import React, { useEffect, useState } from 'react';
import BackCard from '../Assets/backCard';
import InputLinks from '../Assets/linksData';
import { Button, Icon, Input, Modal, ModalContent, ModalHeader } from 'semantic-ui-react';
import TableGrid from '../../Dashboard/Assets/tableGrid';
import axios from 'axios';
import GConf from '../../AssetsM/generalConf';
import {Grid, _ } from "gridjs-react";
import { useNavigate} from 'react-router-dom';
import { toast } from 'react-toastify';
import SKLT from '../../AssetsM/usedSlk';


const PannierCard = ({qteToAdd, setQteTA, AddArticleToList}) =>{
  return(<>
 
          <h5>Ajouter Article aux Pannier : </h5>
          <div className='row'>
              <div className='col-6 align-self-center text-center'>
              <Input type='number' icon='dropbox' value={qteToAdd}   onChange={ (e) =>  setQteTA(e.target.value)}  size="large" iconPosition='left' placeholder='Quantite'  fluid className='mb-1' />
              </div>
              <div className='col-2 align-self-center text-center'>
                  <Button  fluid  size="large" className='rounded-pill bg-danger text-white' onClick={(e) =>  setQteTA(1)}>  <Icon name='recycle' /> </Button> 
              </div>
              <div className='col-4 align-self-center text-center'>
                  <Button  fluid  size="large" className='rounded-pill bg-system-btn' onClick={AddArticleToList}>  <Icon name='shopping basket' /> +  Pannier </Button> 
              </div>
              <div className='col-12 mt-4 text-center'>
                    <Button  size="small" className='rounded-pill text-danger bg-warning' onClick={(e) =>  setQteTA(12)}>  12 </Button> 
                    <Button  size="small" className='rounded-pill text-danger bg-warning' onClick={(e) =>  setQteTA(24)}>  24 </Button> 
                    <Button  size="small" className='rounded-pill text-danger bg-warning' onClick={(e) =>  setQteTA(36)}>  36 </Button> 
                    <Button  size="small" className='rounded-pill text-danger bg-warning' onClick={(e) =>  setQteTA(48)}>  48 </Button> 
                    <Button  size="small" className='rounded-pill text-danger bg-warning' onClick={(e) =>  setQteTA(72)}>  72 </Button> 
                    <Button  size="small" className='rounded-pill text-danger bg-warning' onClick={(e) =>  setQteTA(96)}>  96 </Button> 
                    <Button  size="small" className='rounded-pill text-danger bg-warning' onClick={(e) =>  setQteTA(144)}>  144 </Button> 
              </div>
              <div className='col-12 mt-4 text-center d-none'>
                    <Button  size="small" className='rounded-pill text-secondary bg-basic' onClick={(e) =>  setQteTA(qteToAdd * 5 )}>  x5 </Button> 
                    <Button  size="small" className='rounded-pill text-secondary bg-basic' onClick={(e) =>  setQteTA(qteToAdd * 10)}>  x10 </Button> 
                    <Button  size="small" className='rounded-pill text-secondary bg-basic' onClick={(e) =>  setQteTA(qteToAdd * 15)}>  x15 </Button> 
                    <Button  size="small" className='rounded-pill text-secondary bg-basic' onClick={(e) =>  setQteTA(qteToAdd * 20)}>  x20 </Button> 
                    <Button  size="small" className='rounded-pill text-secondary bg-basic' onClick={(e) =>  setQteTA(qteToAdd * 25)}>  x25 </Button> 
                    <Button  size="small" className='rounded-pill text-secondary bg-basic' onClick={(e) =>  setQteTA(qteToAdd * 30)}>  x30 </Button> 
                    <Button  size="small" className='rounded-pill text-secondary bg-basic' onClick={(e) =>  setQteTA(qteToAdd * 40)}>  x40 </Button> 
                    <Button  size="small" className='rounded-pill text-secondary bg-basic' onClick={(e) =>  setQteTA(qteToAdd * 50)}>  x50 </Button> 
              </div>
          </div>
  </>)
}

function ArticleList() {
  /*#########################[Const]##################################*/
    let [tableData, setTableData] = useState([SKLT.STableSlt]); 
    const [qteToAdd, setQteTA] = useState(1)
    const [AID, setAID] = useState(null)
    const [articleD, setArticleD] = useState({});
    let [open, setOpen] = useState(false)
    let Offline = JSON.parse(localStorage.getItem(`${GConf.SystemTag}_Cmd_Offline`));
    const navigate = useNavigate();

  /*#########################[UseEffect]##################################*/
  useEffect(() => {
    axios.post(`${GConf.ApiCommandeLink}/stock/all`, {
        tag: GConf.SystemTag,
        genre : '1'
      })
      .then(function (response) {
         let testTable = []
         response.data.map( (getData) => testTable.push([
        _(<img className='rounded-circle' width="40px" height="40px" src={`https://assets.ansl.tn/Images/Articles/${getData.Photo_Path}`} alt="user-img" />),
         getData.A_Code,
         getData.Name,
         getData.Genre,
         getData.Quantite,
         getData.Prix_vente,
         getData.Prix_gros,
         _(<Button className='rounded-circle bg-system-btn' icon size='mini' onClick={ (e) => OpenModalFunct(getData)}> <Icon  name='cart plus' /></Button>),
         _(<Button className='rounded-pill bg-system-btn' size='mini' onClick={ (e) => NavigateFunction(`/C/L/cg/info/${getData.A_Code}`)}><span className='d-none d-lg-inline'> Info </span><Icon  name='angle right' /></Button>)
        ],))
        setTableData(testTable)
      }).catch((error) => {
        if(error.request) {
            toast.error(<><div><h5>Probleme de Connextion</h5> Chargemment du ancien stock  </div></>, GConf.TostInternetGonf)   
            let testTable = []
            Offline.stock.map( (getData) => testTable.push([
              _(<img className='rounded-circle' width="40px" height="40px" src={`https://assets.ansl.tn/Images/Articles/${getData.Photo_Path}`} alt="user-img" />),
               getData.A_Code,
               getData.Name,
               getData.Genre,
               getData.Quantite,
               getData.Prix_vente,
               getData.Prix_gros,
               _(<Button className='rounded-pill bg-system-btn' size='mini' onClick={ (e) => NavigateFunction(`/C/L/cg/info/${getData.A_Code}`)}><span className='d-none d-lg-inline'> Info </span><Icon  name='angle right' /></Button>)
              ],))
              setTableData(testTable)
        }
    });
    }, [])
  
  /*#########################[Functions]##################################*/
  const NavigateFunction = (link) => {  navigate(link) }

  const AddArticleToList = ()=>{

      const searchObject = Offline.pannierArticles.find((article) => article.A_Code == AID);
      if (searchObject) {
          let IndexOfArticle = Offline.pannierArticles.findIndex((article) => article.A_Code == AID)
          Offline.pannierArticles[IndexOfArticle].Qte = parseInt(Offline.pannierArticles[IndexOfArticle].Qte) + parseInt(qteToAdd)
          Offline.pannierArticles[IndexOfArticle].PU = ((Offline.pannierArticles[IndexOfArticle].Qte) * Offline.pannierArticles[IndexOfArticle].Prix ).toFixed(3)
          
          localStorage.setItem(`${GConf.SystemTag}_Cmd_Offline`,  JSON.stringify(Offline));
          setQteTA(1)
          toast.info(`${searchObject.Name} Reajouter Avec Quantite : ${qteToAdd}`, GConf.TostPannier)
      } else {
          let prix_u = (parseFloat(articleD.Prix_vente) * parseInt(qteToAdd)).toFixed(3)
          Offline.pannierArticles.push({id: Offline.pannierArticles.length+1 , A_Code: articleD.A_Code, Name: articleD.Name, Prix: parseFloat(articleD.Prix_vente), Qte: parseFloat(qteToAdd), PU: prix_u})
          localStorage.setItem(`${GConf.SystemTag}_Cmd_Offline`,  JSON.stringify(Offline));
          setQteTA(1)
          toast.info(`${articleD.Name}  Ajouter !, La Quantite est : ${qteToAdd}`, GConf.TostPannier)

      }
  }
  const OpenModalFunct = (articelData) => {
    setArticleD(articelData)
    setAID(articelData.A_Code)
    setOpen(true)
  }

  return ( <>
        <BackCard data={InputLinks.backCard.cgList}/>
       
        <br />
        <div className='container-fluid'>
            <TableGrid tableData={tableData} commandes columns={['*','Code','Nom','Genre','Stock','Prix','PrixG','Pannier','Voir']} />
        </div>
        <Modal
            size='large'
            open={open}
            onClose={() => setOpen(false)}
          >
            <ModalHeader> Ajouter Aux Pannier </ModalHeader>
            <ModalContent>
              <PannierCard qteToAdd={qteToAdd} setQteTA={setQteTA} AddArticleToList={AddArticleToList} />
            </ModalContent>
          </Modal>
        </> );
}

export default ArticleList