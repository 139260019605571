const AlimDefaultCateg = [
    {id:0, page:1, genre:'INITIALE', color:'#9430cf', articles:[{id:1, simple:true , dim:3,  codeAB:'6191513502212', heigPhoto:'155', widtPhoto:'80'},]},
    {id:1, page:2, genre: "CONSERVATION 1", color: "#c9263f", articles: [
                // { id: 1, simple: true, dim: 3, codeAB: "6191444400496", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6194055500135", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194003220252", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194003220245", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194000101066", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6194000101059", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194003220115", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194003220078", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191444400274", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191444400397", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191444400267", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194055500517", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194000100694", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194000100663", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194000100595", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194055500630", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id:2, page:3, genre: "CONSERVATION 2", color: "#c9263f", articles: [
                { id: 1, simple: true, dim: 3, codeAB: "6194000240192", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6194000240505", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6194000240178", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194019202440", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191460400821", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191586400217", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191460400791", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191460400982", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191460400180", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191460400203", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191444400144", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6987649843271", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191444400724", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194000100656", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191444400229", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194003220016", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id:3, page:4, genre:'JOUDA', color:'#f04c41', articles:[
                {id:1, simple:true , dim:3, codeAB:'6194001420067', heigPhoto:'190', widtPhoto:'80'},
                {id:2, simple:true , dim:3, codeAB:'6194001480412', heigPhoto:'190', widtPhoto:'80'},
                {id:3, simple:true , dim:3, codeAB:'6194001420012', heigPhoto:'190', widtPhoto:'80'},
                // {id:4, simple:true , dim:3, codeAB:'6194001410013', heigPhoto:'190', widtPhoto:'80'},
                {id:5, simple:true , dim:3, codeAB:'6194001480641', heigPhoto:'190', widtPhoto:'80'},
                // {id:6, simple:true , dim:3, codeAB:'6194001481297', heigPhoto:'190', widtPhoto:'80'},
                // {id:7, simple:true , dim:3, codeAB:'6194001481303', heigPhoto:'190', widtPhoto:'80'},
                {id:8, simple:true , dim:3, codeAB:'6194001481327', heigPhoto:'190', widtPhoto:'80'},
                {id:9, simple:true , dim:3, codeAB:'619400142001', heigPhoto:'190', widtPhoto:'80'},
                {id:10, simple:true , dim:3, codeAB:'6194001481433', heigPhoto:'190', widtPhoto:'80'},
                {id:11, simple:true , dim:3, codeAB:'6194001480672', heigPhoto:'190', widtPhoto:'80'},
                {id:12, simple:true , dim:3, codeAB:'6194001481310', heigPhoto:'190', widtPhoto:'80'},
                {id:13, simple:true , dim:3, codeAB:'619400148040', heigPhoto:'190', widtPhoto:'80'},
                {id:14, simple:true , dim:3, codeAB:'6194001410044', heigPhoto:'190', widtPhoto:'80'},
                // {id:15, simple:true , dim:3, codeAB:'6194024504072', heigPhoto:'190', widtPhoto:'80'},
                // {id:16, simple:true , dim:3, codeAB:'6194001481433', heigPhoto:'190', widtPhoto:'80'},

    ]},
    {id:4, page:5, genre:'ABIDA', color:'#3b75d9', articles:[
                // {id:1, simple:true , dim:3, codeAB:'6194024504096', heigPhoto:'190', widtPhoto:'80'},
                // {id:2, simple:true , dim:3, codeAB:'6194024504089', heigPhoto:'190', widtPhoto:'80'},
                {id:3, simple:true , dim:3, codeAB:'6194024504539', heigPhoto:'190', widtPhoto:'80'},
                //{id:4, simple:true , dim:3, codeAB:'6194024504539', heigPhoto:'190', widtPhoto:'80'},
                {id:5, simple:true , dim:3, codeAB:'6194024504072', heigPhoto:'190', widtPhoto:'80'},
                {id:6, simple:true , dim:3, codeAB:'6194024502047', heigPhoto:'190', widtPhoto:'80'},
                // {id:7, simple:true , dim:3, codeAB:'6194024504331', heigPhoto:'190', widtPhoto:'80'},
                {id:8, simple:true , dim:3, codeAB:'6194024504119', heigPhoto:'190', widtPhoto:'80'},
                {id:9, simple:true , dim:3, codeAB:'6194024504126', heigPhoto:'190', widtPhoto:'80'},
                {id:10, simple:true , dim:3, codeAB:'6194024504218', heigPhoto:'190', widtPhoto:'80'},
                // {id:11, simple:true , dim:3, codeAB:'619402450412', heigPhoto:'190', widtPhoto:'80'},
                {id:12, simple:true , dim:3, codeAB:'6194024504393', heigPhoto:'190', widtPhoto:'80'},
                {id:13, simple:true , dim:3, codeAB:'6194024504348', heigPhoto:'190', widtPhoto:'80'},
                // {id:14, simple:true , dim:3, codeAB:'6194024504393', heigPhoto:'190', widtPhoto:'80'},
                // {id:15, simple:true , dim:3, codeAB:'6194024504393', heigPhoto:'190', widtPhoto:'80'},
                // {id:16, simple:true , dim:3, codeAB:'6194024504393', heigPhoto:'190', widtPhoto:'80'},
    ]},
    {id:5, page:6, genre: "THON & SARDINE 1", color: "#69941f", articles: [
                // { id: 1, simple: true, dim: 3, codeAB: "6191444400304", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191571803177", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191500700324", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191500700232", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191500700188", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191500700638", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "619500700041", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194029100187", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191506801575", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191571801555", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191463401306", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6194029100675", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191444400298", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191519600103", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191519600202", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191519600127", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id:6, page:7, genre: "THON & SARDINE 2", color: "#69941f", articles: [
                // { id: 1, simple: true, dim: 3, codeAB: "6191519600301", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191519600035", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191519600240", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191444400342", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191444400304", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191500700300", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191500700584", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191500700287", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191500700638", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191500700607", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191508400622", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191571802576", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id:7, page:8, genre: "LA GOULETTE", color: "#25a0c2", articles: [
                { id: 1, simple: true, dim: 3, codeAB: "6191571801128", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191571801425", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191571801432", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191571801180", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191571800961", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191571800923", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191571800947", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191571801623", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191508400981", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191508400974", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191460401231", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191571801180", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191571801432", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191571801425", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191571801425", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191571801128", heigPhoto: "190", widtPhoto: "100"},
    ]},
    {id:8, page:9, genre: "SIDI DAOUD", color: "#0c7d14", articles: [
                { id: 1, simple: true, dim: 3, codeAB: "6191520002637", heigPhoto: "210", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191520002033", heigPhoto: "210", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191520002996", heigPhoto: "210", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191520001982", heigPhoto: "210", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191520000626", heigPhoto: "210", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191520002095", heigPhoto: "210", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191520002033", heigPhoto: "210", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191520001975", heigPhoto: "210", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191520002989", heigPhoto: "210", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191520002002", heigPhoto: "210", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191520000046", heigPhoto: "210", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191520000367", heigPhoto: "210", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191520001296", heigPhoto: "210", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191520001302", heigPhoto: "210", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191520001319", heigPhoto: "210", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191520000251", heigPhoto: "210", widtPhoto: "100"},
    ]},
    {id:9, page:10, genre: "CHAMIA 1", color: "#726980", articles: [
                { id: 1, simple: true, dim: 3, codeAB: "6194002491189", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194002490953", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id:10, page:11, genre: "CHAMIA 2", color: "#726980", articles: [
                { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194002430195", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "61940475", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id:11, page:12, genre:'SHEHRAZED', color:'#5dd620', articles:[
                {id:1, simple:true , dim:3, codeAB:'6194036802258', heigPhoto:'155', widtPhoto:'100'},
                {id:2, simple:true , dim:3, codeAB:'6194036802227', heigPhoto:'155', widtPhoto:'100'},
                {id:3, simple:true , dim:3, codeAB:'6194036802326', heigPhoto:'155', widtPhoto:'100'},
                {id:4, simple:true , dim:3, codeAB:'6194036802272', heigPhoto:'155', widtPhoto:'100'},
                {id:5, simple:true , dim:3, codeAB:'6194036800438', heigPhoto:'155', widtPhoto:'100'},
                {id:6, simple:true , dim:3, codeAB:'6194036800506', heigPhoto:'155', widtPhoto:'100'},
                {id:7, simple:true , dim:3, codeAB:'6194036800476', heigPhoto:'155', widtPhoto:'100'},
                {id:8, simple:true , dim:3, codeAB:'6194036801572', heigPhoto:'155', widtPhoto:'100'},
                {id:9, simple:true , dim:3, codeAB:'6194036802340', heigPhoto:'155', widtPhoto:'100'},
                {id:10, simple:true , dim:3, codeAB:'6194036802265', heigPhoto:'155', widtPhoto:'100'},
                {id:11, simple:true , dim:3, codeAB:'6194036802197', heigPhoto:'155', widtPhoto:'100'},
                {id:12, simple:true , dim:3, codeAB:'6194036802319', heigPhoto:'155', widtPhoto:'100'},
                {id:13, simple:true , dim:3, codeAB:'6194036800902', heigPhoto:'155', widtPhoto:'100'},
                {id:14, simple:true , dim:3, codeAB:'6194036801374', heigPhoto:'155', widtPhoto:'100'},
                {id:15, simple:true , dim:3, codeAB:'6194036800810', heigPhoto:'155', widtPhoto:'100'},
                {id:16, simple:true , dim:3, codeAB:'6194036800940', heigPhoto:'155', widtPhoto:'100'},
    ]},
    {id:12, page:13, genre:'SHEHRAZED', color:'#5dd620', articles:[
                {id:1, simple:true , dim:3, codeAB:'6194036802203', heigPhoto:'155', widtPhoto:'100'},
                {id:2, simple:true , dim:3, codeAB:'6194036801701', heigPhoto:'155', widtPhoto:'100'},
                {id:3, simple:true , dim:3, codeAB:'6194036802357', heigPhoto:'155', widtPhoto:'100'},
                {id:4, simple:true , dim:3, codeAB:'6194036802296', heigPhoto:'155', widtPhoto:'100'},
                {id:5, simple:true , dim:3, codeAB:'6194036802289', heigPhoto:'155', widtPhoto:'100'},
                {id:6, simple:true , dim:3, codeAB:'6194036802340', heigPhoto:'155', widtPhoto:'100'},
                {id:7, simple:true , dim:3, codeAB:'6194036802210', heigPhoto:'155', widtPhoto:'100'},
                {id:8, simple:true , dim:3, codeAB:'6194036802241', heigPhoto:'155', widtPhoto:'100'},
                {id:9, simple:true , dim:3, codeAB:'6194036802821', heigPhoto:'155', widtPhoto:'100'},
                {id:10, simple:true , dim:3, codeAB:'6194036802791', heigPhoto:'155', widtPhoto:'100'},
                {id:11, simple:true , dim:3, codeAB:'6194036802654', heigPhoto:'155', widtPhoto:'100'},
                {id:12, simple:true , dim:3, codeAB:'6194036802814', heigPhoto:'155', widtPhoto:'100'},
                // {id:13, simple:true , dim:3, codeAB:'6194036800902', heigPhoto:'155', widtPhoto:'100'},
                // {id:14, simple:true , dim:3, codeAB:'6194036801374', heigPhoto:'155', widtPhoto:'100'},
                // {id:15, simple:true , dim:3, codeAB:'6194036800810', heigPhoto:'155', widtPhoto:'100'},
                // {id:16, simple:true , dim:3, codeAB:'6194036800940', heigPhoto:'155', widtPhoto:'100'},
    ]},
    {id:13, page:14, genre: "LE MOULIN ", color: "#d92537", articles: [
                { id: 1, simple: true, dim: 3, codeAB: "6194017502160", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194017506656", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194017501491", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194017503761", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194017502528", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194017500142", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id:14, page:15, genre: "LE MOULIN ", color: "#d92537", articles: [
            { id: 1, simple: true, dim: 3, codeAB: "6194017502542", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194017503785", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194017500067", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194017503822", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194017503136", heigPhoto: "190", widtPhoto: "100"},
        //     { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
        //     { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
        //     { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
        //     { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
        //     { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
        //     { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
        //     { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
        //     { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
        //     { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
        //     { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"},
        //     { id: 1, simple: true, dim: 3, codeAB: "6194002490977", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id:15, page:16, genre:'SAIDA 1', color:'#f5162c', articles:[
                {id:1, simple:true , dim:3, codeAB:'6194008511010', heigPhoto:'190', widtPhoto:'100'},
                {id:2, simple:true , dim:3, codeAB:'6194008554963', heigPhoto:'190', widtPhoto:'100'},
                {id:3, simple:true , dim:3, codeAB:'6194008555380', heigPhoto:'190', widtPhoto:'100'},
                {id:4, simple:true , dim:3, codeAB:'6194008515520', heigPhoto:'190', widtPhoto:'100'},
                {id:5, simple:true , dim:3, codeAB:'6194008554826', heigPhoto:'190', widtPhoto:'100'},
                {id:6, simple:true , dim:3, codeAB:'6194008554833', heigPhoto:'190', widtPhoto:'100'},
                {id:7, simple:true , dim:3, codeAB:'6194008551030', heigPhoto:'190', widtPhoto:'100'},
                {id:8, simple:true , dim:3, codeAB:'6194008552204', heigPhoto:'190', widtPhoto:'100'},
                {id:9, simple:true , dim:3, codeAB:'6194008543028', heigPhoto:'190', widtPhoto:'100'},
                {id:10, simple:true , dim:3, codeAB:'6194008555274', heigPhoto:'190', widtPhoto:'100'},
                {id:11, simple:true , dim:3, codeAB:'6194008518040', heigPhoto:'190', widtPhoto:'100'},
                {id:12, simple:true , dim:3, codeAB:'6194008518026', heigPhoto:'190', widtPhoto:'100'},
                {id:13, simple:true , dim:3, codeAB:'6194008555298', heigPhoto:'190', widtPhoto:'100'},
                {id:14, simple:true , dim:3, codeAB:'6194008554482', heigPhoto:'190', widtPhoto:'100'},
                {id:15, simple:true , dim:3, codeAB:'6194008516107', heigPhoto:'190', widtPhoto:'100'},
                {id:16, simple:true , dim:3, codeAB:'6194008555335', heigPhoto:'190', widtPhoto:'100'},
    ]},
    {id:16, page:17, genre:'SAIDA 2 ', color:'#f5162c', articles:[
                {id:1, simple:true , dim:3, codeAB:'6194008525017', heigPhoto:'190', widtPhoto:'100'},
                {id:2, simple:true , dim:3, codeAB:'6194008541055', heigPhoto:'190', widtPhoto:'100'},
                {id:3, simple:true , dim:3, codeAB:'6194008555175', heigPhoto:'190', widtPhoto:'100'},
                {id:4, simple:true , dim:3, codeAB:'6194008511027', heigPhoto:'190', widtPhoto:'100'},
                {id:5, simple:true , dim:3, codeAB:'6194008555236', heigPhoto:'190', widtPhoto:'100'},
                {id:6, simple:true , dim:3, codeAB:'6194008530059', heigPhoto:'190', widtPhoto:'100'},
                {id:7, simple:true , dim:3, codeAB:'6194008514011', heigPhoto:'190', widtPhoto:'100'},
                // {id:8, simple:true , dim:3, codeAB:'6194008552204', heigPhoto:'190', widtPhoto:'100'},
                // {id:9, simple:true , dim:3, codeAB:'6194008543028', heigPhoto:'190', widtPhoto:'100'},
                // {id:10, simple:true , dim:3, codeAB:'6194008555274', heigPhoto:'190', widtPhoto:'100'},
                // {id:11, simple:true , dim:3, codeAB:'6194008518040', heigPhoto:'190', widtPhoto:'100'},
                // {id:12, simple:true , dim:3, codeAB:'6194008518026', heigPhoto:'190', widtPhoto:'100'},
                // {id:13, simple:true , dim:3, codeAB:'6194008555298', heigPhoto:'190', widtPhoto:'100'},
                // {id:14, simple:true , dim:3, codeAB:'6194008554482', heigPhoto:'190', widtPhoto:'100'},
                // {id:15, simple:true , dim:3, codeAB:'6194008516107', heigPhoto:'190', widtPhoto:'100'},
                // {id:16, simple:true , dim:3, codeAB:'6194008555335', heigPhoto:'190', widtPhoto:'100'},
    ]},
    {id:17, page:18, genre:'LBM', color:'#ddf013', articles:[
                { id: 1, simple: true, dim: 3, codeAB: "6191307400090", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191307400175", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191307400168", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191307400144", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191307400205", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191307400243", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191307400786", heigPhoto: "190", widtPhoto: "100"},
                { id:7,  simple:true , dim:3, codeAB:'6191307400762', heigPhoto:'190', widtPhoto:'100'},
                { id:8,  simple:true , dim:3, codeAB:'6191307400342', heigPhoto:'190', widtPhoto:'100'},
                // {id:9, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                // {id:10, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                // {id:11, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                // {id:12, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                // {id:13, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                // {id:14, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                // {id:15, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                // {id:16, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
    ]},
    {id:18, page:19, genre: "TOM", color: "#2e49bf", articles: [
                { id: 1, simple: true, dim: 3, codeAB: "6194000511513", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194000521017", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6291003081910", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id:19, page:20, genre: "CHOCOLAT", color: "#381f0d", articles: [
                { id: 1, simple: true, dim: 3, codeAB: "6191402902857", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191402902604", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005448623", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005420186", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191544100531", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191544101224", heigPhoto: "190", widtPhoto: "100"},
                //{ id: 1, simple: true, dim: 3, codeAB: "6194005412068", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "16194005420213", heigPhoto: "190", widtPhoto: "100"},
                //{ id: 1, simple: true, dim: 3, codeAB: "6194005412051", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005461110", heigPhoto: "190", widtPhoto: "100"},
                //{ id: 1, simple: true, dim: 3, codeAB: "6194005412082", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191544100616", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191544100418", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id:20, page:21, genre: "SAID", color: "#850314", articles: [
                { id: 1, simple: true, dim: 3, codeAB: "6194005420100", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "619400542155", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005400812", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005400874", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005451227", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005452002", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005400911", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005453030", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005452026", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005452040", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005401123", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005420049", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005453047", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005453023", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005453016", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005451043", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id:21, page:22, genre: "MAESTRO", color: "#05239e", articles: [
                { id: 1, simple: true, dim: 3, codeAB: "6194005448449", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005448487", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005448562", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191544104072", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005446520", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005446407", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005447909", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005447930", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005449224", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005449217", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005447916", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005447947", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005445011", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005446353", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005448418", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6194005447961", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id:22, page:23, genre:'ARTISTO', color:'#33070d', articles:[
                {id:1, simple:true , dim:3, codeAB:'6191448600113', heigPhoto:'190', widtPhoto:'100'},
                {id:2, simple:true , dim:3, codeAB:'6191448600120', heigPhoto:'190', widtPhoto:'100'},
                {id:3, simple:true , dim:3, codeAB:'6191448600557', heigPhoto:'190', widtPhoto:'100'},
                {id:4, simple:true , dim:3, codeAB:'6191448600540', heigPhoto:'190', widtPhoto:'100'},
                {id:5, simple:true , dim:3, codeAB:'6191448600984', heigPhoto:'190', widtPhoto:'100'},
                {id:6, simple:true , dim:3, codeAB:'6191448600670', heigPhoto:'190', widtPhoto:'100'},
                {id:7, simple:true , dim:3, codeAB:'6191448600694', heigPhoto:'190', widtPhoto:'100'},
                {id:8, simple:true , dim:3, codeAB:'6191448600939', heigPhoto:'190', widtPhoto:'100'},
                {id:9, simple:true , dim:3, codeAB:'6191448602377', heigPhoto:'190', widtPhoto:'100'},
                {id:10, simple:true , dim:3, codeAB:'6191448600298', heigPhoto:'190', widtPhoto:'100'},
                {id:11, simple:true , dim:3, codeAB:'6191448600311', heigPhoto:'190', widtPhoto:'100'},
                {id:12, simple:true , dim:3, codeAB:'6191448600113', heigPhoto:'190', widtPhoto:'100'},
                // {id:13, simple:true , dim:3, codeAB:'6191448600113', heigPhoto:'190', widtPhoto:'100'},
                // {id:14, simple:true , dim:3, codeAB:'6191448600113', heigPhoto:'190', widtPhoto:'100'},
                // {id:15, simple:true , dim:3, codeAB:'6191448600113', heigPhoto:'190', widtPhoto:'100'},
                // {id:16, simple:true , dim:3, codeAB:'6191448600113', heigPhoto:'190', widtPhoto:'100'},
    ]},
    {id:23, page:24, genre:'PRESIDENT', color:'#85a5ff', articles:[
                {id:1, simple:true , dim:3, codeAB:'6191513502212', heigPhoto:'190', widtPhoto:'80'},
                {id:2, simple:true , dim:3, codeAB:'6191513501017', heigPhoto:'190', widtPhoto:'80'},
                {id:3, simple:true , dim:3, codeAB:'6191513501086', heigPhoto:'190', widtPhoto:'80'},
                {id:4, simple:true , dim:3, codeAB:'6191513502014', heigPhoto:'190', widtPhoto:'80'},
                {id:5, simple:true , dim:3, codeAB:'6191513502489', heigPhoto:'190', widtPhoto:'80'},
                {id:6, simple:true , dim:3, codeAB:'6191513501222', heigPhoto:'190', widtPhoto:'80'},
                {id:7, simple:true , dim:3, codeAB:'6191513504384', heigPhoto:'190', widtPhoto:'80'},
                {id:8, simple:true , dim:3, codeAB:'6191513501062', heigPhoto:'190', widtPhoto:'80'},
                {id:9, simple:true , dim:3, codeAB:'6191513504278', heigPhoto:'190', widtPhoto:'80'},
                {id:10, simple:true , dim:3, codeAB:'6191513502045', heigPhoto:'190', widtPhoto:'80'},
                {id:11, simple:true , dim:3, codeAB:'6191513504520', heigPhoto:'190', widtPhoto:'80'},
                {id:12, simple:true , dim:3, codeAB:'6191513504438', heigPhoto:'190', widtPhoto:'80'},
                {id:13, simple:true , dim:3, codeAB:'6191513501574', heigPhoto:'190', widtPhoto:'80'},
                {id:14, simple:true , dim:3, codeAB:'6191513503363', heigPhoto:'190', widtPhoto:'80'},
                {id:15, simple:true , dim:3, codeAB:'6191513501277', heigPhoto:'190', widtPhoto:'80'},
                {id:16, simple:true , dim:3, codeAB:'6191513504445', heigPhoto:'190', widtPhoto:'80'},
    ]},
    {id:24, page:25, genre:'PRESIDENT', color:'#85a5ff', articles:[
                {id:1, simple:true , dim:3, codeAB:'6191513501796', heigPhoto:'190', widtPhoto:'80'},
                {id:2, simple:true , dim:3, codeAB:'6191513504551', heigPhoto:'190', widtPhoto:'80'},
                {id:3, simple:true , dim:3, codeAB:'6191513501628', heigPhoto:'190', widtPhoto:'80'},
                {id:4, simple:true , dim:3, codeAB:'6191513502380', heigPhoto:'190', widtPhoto:'80'},
                {id:5, simple:true , dim:3, codeAB:'6191513503295', heigPhoto:'190', widtPhoto:'80'},
                {id:6, simple:true , dim:3, codeAB:'6191513503196', heigPhoto:'190', widtPhoto:'80'},
                {id:7, simple:true , dim:3, codeAB:'6191513501123', heigPhoto:'190', widtPhoto:'80'},
                {id:8, simple:true , dim:3, codeAB:'6194049403336', heigPhoto:'190', widtPhoto:'80'},
                {id:9, simple:true , dim:3, codeAB:'6194049403305', heigPhoto:'190', widtPhoto:'80'},
                {id:10, simple:true , dim:3, codeAB:'6191513504407', heigPhoto:'190', widtPhoto:'80'},
                {id:11, simple:true , dim:3, codeAB:'6191513504490', heigPhoto:'190', widtPhoto:'80'},
                {id:12, simple:true , dim:3, codeAB:'6191513504421', heigPhoto:'190', widtPhoto:'80'},
                // {id:13, simple:true , dim:3, codeAB:'6191513501574', heigPhoto:'190', widtPhoto:'80'},
                // {id:14, simple:true , dim:3, codeAB:'6191513503363', heigPhoto:'190', widtPhoto:'80'},
                // {id:15, simple:true , dim:3, codeAB:'6191513501277', heigPhoto:'190', widtPhoto:'80'},
                // {id:16, simple:true , dim:3, codeAB:'6191513504445', heigPhoto:'190', widtPhoto:'80'},
    ]},
    {id:25, page:26, genre:'PATTES 1', color:'#ba9027', articles:[
                {id:1, simple:true , dim:3, codeAB:'6223006710153', heigPhoto:'155', widtPhoto:'100'},
                {id:2, simple:true , dim:3, codeAB:'6191519401083', heigPhoto:'155', widtPhoto:'100'},
                {id:3, simple:true , dim:3, codeAB:'6191519401069', heigPhoto:'155', widtPhoto:'100'},
                {id:4, simple:true , dim:3, codeAB:'6191519401113', heigPhoto:'155', widtPhoto:'100'},
                // {id:5, simple:true , dim:3, codeAB:'6191575102061', heigPhoto:'155', widtPhoto:'100'},
                // {id:6, simple:true , dim:3, codeAB:'6191575100463', heigPhoto:'155', widtPhoto:'100'},
                // {id:7, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                // {id:8, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                // {id:9, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                // {id:10, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                // {id:11, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                // {id:12, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                // {id:13, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                // {id:14, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                // {id:15, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                // {id:16, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
    ]},
    {id:26, page:27, genre:'PATTES 2 ', color:'#ba9027', articles:[
                {id:1, simple:true , dim:3, codeAB:'6191519400796', heigPhoto:'155', widtPhoto:'100'},
                {id:2, simple:true , dim:3, codeAB:'1022', heigPhoto:'155', widtPhoto:'100'},
                {id:3, simple:true , dim:3, codeAB:'6191519401014', heigPhoto:'155', widtPhoto:'100'},
                {id:4, simple:true , dim:3, codeAB:'1020', heigPhoto:'155', widtPhoto:'100'},
                // {id:5, simple:true , dim:3, codeAB:'6191575102061', heigPhoto:'155', widtPhoto:'100'},
                // {id:6, simple:true , dim:3, codeAB:'6191575100463', heigPhoto:'155', widtPhoto:'100'},
                // {id:7, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                // {id:8, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                // {id:9, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                // {id:10, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                // {id:11, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                // {id:12, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                // {id:13, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                // {id:14, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                // {id:15, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
                // {id:16, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
    ]},
    {id:27, page:28, genre:'WARDA', color:'#fcb36a', articles:[
        {id:1, simple:true , dim:3, codeAB:'6191564600028', heigPhoto:'155', widtPhoto:'100'},
        {id:2, simple:true , dim:3, codeAB:'6197564600042', heigPhoto:'155', widtPhoto:'100'},
        {id:3, simple:true , dim:3, codeAB:'6191564600394', heigPhoto:'155', widtPhoto:'100'},
        {id:4, simple:true , dim:3, codeAB:'6191564600059', heigPhoto:'155', widtPhoto:'100'},
        {id:5, simple:true , dim:3, codeAB:'6191564600400', heigPhoto:'155', widtPhoto:'100'},
        {id:6, simple:true , dim:3, codeAB:'6191564600011', heigPhoto:'155', widtPhoto:'100'},
        {id:7, simple:true , dim:3, codeAB:'6194043401048', heigPhoto:'155', widtPhoto:'100'},
        {id:8, simple:true , dim:3, codeAB:'6194043402915', heigPhoto:'155', widtPhoto:'100'},
        {id:9, simple:true , dim:3, codeAB:'6194043403653', heigPhoto:'155', widtPhoto:'100'},
        {id:10, simple:true , dim:3, codeAB:'6194043403608', heigPhoto:'155', widtPhoto:'100'},
        {id:11, simple:true , dim:3, codeAB:'6194043402571', heigPhoto:'155', widtPhoto:'100'},
        {id:12, simple:true , dim:3, codeAB:'6194043402588', heigPhoto:'155', widtPhoto:'100'},
        {id:13, simple:true , dim:3, codeAB:'6194043402922', heigPhoto:'155', widtPhoto:'100'},
        // {id:14, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
        // {id:15, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
        // {id:16, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'155', widtPhoto:'100'},
    ]},
    {id:28, page:29, genre:'VANOISE', color:'#04acc9', articles:[
                {id:1, simple:true , dim:3, codeAB:'6191402800160', heigPhoto:'190', widtPhoto:'100'},
                {id:2, simple:true , dim:3, codeAB:'6191403002426', heigPhoto:'190', widtPhoto:'100'},
                {id:3, simple:true , dim:3, codeAB:'6191403002945', heigPhoto:'190', widtPhoto:'100'},
                {id:4, simple:true , dim:3, codeAB:'6191402803192', heigPhoto:'190', widtPhoto:'100'},
                {id:5, simple:true , dim:3, codeAB:'6191402804007', heigPhoto:'190', widtPhoto:'100'},
                {id:6, simple:true , dim:3, codeAB:'6191402800146', heigPhoto:'190', widtPhoto:'100'},
                {id:7, simple:true , dim:3, codeAB:'6191402802461', heigPhoto:'190', widtPhoto:'100'},
                {id:8, simple:true , dim:3, codeAB:'6191402803680', heigPhoto:'190', widtPhoto:'100'},
                {id:9, simple:true , dim:3, codeAB:'6191402804014', heigPhoto:'190', widtPhoto:'100'},
                {id:10, simple:true , dim:3, codeAB:'6191402800061', heigPhoto:'190', widtPhoto:'100'},
                {id:11, simple:true , dim:3, codeAB:'6191402802362', heigPhoto:'190', widtPhoto:'100'},
                {id:12, simple:true , dim:3, codeAB:'6191402803444', heigPhoto:'190', widtPhoto:'100'},
                {id:13, simple:true , dim:3, codeAB:'6191402803925', heigPhoto:'190', widtPhoto:'100'},
                {id:14, simple:true , dim:3, codeAB:'6191402801327', heigPhoto:'190', widtPhoto:'100'},
                {id:15, simple:true , dim:3, codeAB:'6191402803536', heigPhoto:'190', widtPhoto:'100'},
                {id:16, simple:true , dim:3, codeAB:'6191402801495', heigPhoto:'190', widtPhoto:'100'},
    ]},
    {id:29, page:30, genre:'SMART CHEF 1', color:'#ffc847', articles:[
                {id:1, simple:true , dim:3, codeAB:'6191514020654', heigPhoto:'190', widtPhoto:'80'},
                {id:2, simple:true , dim:3, codeAB:'6191514011300', heigPhoto:'190', widtPhoto:'80'},
                {id:3, simple:true , dim:3, codeAB:'6191514015155', heigPhoto:'190', widtPhoto:'80'},
                {id:4, simple:true , dim:3, codeAB:'6191514030158', heigPhoto:'190', widtPhoto:'80'},
                {id:5, simple:true , dim:3, codeAB:'61940529', heigPhoto:'190', widtPhoto:'80'},
                {id:6, simple:true , dim:3, codeAB:'6191514016657', heigPhoto:'190', widtPhoto:'80'},
                {id:7, simple:true , dim:3, codeAB:'6191514039755', heigPhoto:'190', widtPhoto:'80'},
                {id:8, simple:true , dim:3, codeAB:'6191514010426', heigPhoto:'190', widtPhoto:'80'},
                {id:9, simple:true , dim:3, codeAB:'6191514018859', heigPhoto:'190', widtPhoto:'80'},
                {id:10, simple:true , dim:3, codeAB:'6191514019207', heigPhoto:'190', widtPhoto:'80'},
                {id:11, simple:true , dim:3, codeAB:'6191514018750', heigPhoto:'190', widtPhoto:'80'},
                {id:12, simple:true , dim:3, codeAB:'6191514001073', heigPhoto:'190', widtPhoto:'80'},
                {id:13, simple:true , dim:3, codeAB:'6191514030257', heigPhoto:'190', widtPhoto:'80'},
                {id:14, simple:true , dim:3, codeAB:'61940574', heigPhoto:'190', widtPhoto:'80'},
                {id:15, simple:true , dim:3, codeAB:'6191514037256', heigPhoto:'190', widtPhoto:'80'},
                {id:16, simple:true , dim:3, codeAB:'6191514006009', heigPhoto:'190', widtPhoto:'80'},
    ]},
    {id:30, page:31, genre:'SMART CHEF 2', color:'#ffc847', articles:[
                {id:1, simple:true , dim:3, codeAB:'6191514020005', heigPhoto:'155', widtPhoto:'80'},
                {id:2, simple:true , dim:3, codeAB:'6191514005255', heigPhoto:'155', widtPhoto:'80'},
                {id:3, simple:true , dim:3, codeAB:'6191514001257', heigPhoto:'155', widtPhoto:'80'},
                {id:4, simple:true , dim:3, codeAB:'6191514004265', heigPhoto:'155', widtPhoto:'80'},
                {id:5, simple:true , dim:3, codeAB:'6191514011058', heigPhoto:'155', widtPhoto:'80'},
                {id:6, simple:true , dim:3, codeAB:'6191514011102', heigPhoto:'155', widtPhoto:'80'},
                {id:7, simple:true , dim:3, codeAB:'6191514041680', heigPhoto:'155', widtPhoto:'80'},
                {id:8, simple:true , dim:3, codeAB:'6191514005002', heigPhoto:'155', widtPhoto:'80'},
                {id:9, simple:true , dim:3, codeAB:'6191514013601', heigPhoto:'155', widtPhoto:'80'},
                // {id:10, simple:true , dim:3, codeAB:'6191514039601', heigPhoto:'155', widtPhoto:'80'},
                // {id:11, simple:true , dim:3, codeAB:'6191514039601', heigPhoto:'155', widtPhoto:'80'},
                // {id:12, simple:true , dim:3, codeAB:'6191514039601', heigPhoto:'155', widtPhoto:'80'},
                // {id:13, simple:true , dim:3, codeAB:'6191514039601', heigPhoto:'155', widtPhoto:'80'},
                // {id:14, simple:true , dim:3, codeAB:'6191514039601', heigPhoto:'155', widtPhoto:'80'},
                // {id:15, simple:true , dim:3, codeAB:'6191514039601', heigPhoto:'155', widtPhoto:'80'},
                // {id:16, simple:true , dim:3, codeAB:'6191514039601', heigPhoto:'155', widtPhoto:'80'},
    ]},
    {id:31, page:32, genre:'BONNA', color:'#0a6603', articles:[
                {id:1, simple:true , dim:3, codeAB:'6191575100456', heigPhoto:'190', widtPhoto:'100'},
                {id:2, simple:true , dim:3, codeAB:'6191575102061', heigPhoto:'190', widtPhoto:'100'},
                {id:3, simple:true , dim:3, codeAB:'6191575101668', heigPhoto:'190', widtPhoto:'100'},
                {id:4, simple:true , dim:3, codeAB:'6191575100531', heigPhoto:'190', widtPhoto:'100'},
                {id:5, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'190', widtPhoto:'100'},
                {id:6, simple:true , dim:3, codeAB:'6191575100500', heigPhoto:'190', widtPhoto:'100'},
                {id:7, simple:true , dim:3, codeAB:'6191575100432', heigPhoto:'190', widtPhoto:'100'},
                {id:8, simple:true , dim:3, codeAB:'6191575101767', heigPhoto:'190', widtPhoto:'100'},
                {id:9, simple:true , dim:3, codeAB:'1047', heigPhoto:'190', widtPhoto:'100'},
                {id:10, simple:true , dim:3, codeAB:'6191575102047', heigPhoto:'190', widtPhoto:'100'},
                {id:11, simple:true , dim:3, codeAB:'6191575100043', heigPhoto:'190', widtPhoto:'100'},
                {id:12, simple:true , dim:3, codeAB:'6191575100463', heigPhoto:'190', widtPhoto:'100'},
                {id:13, simple:true , dim:3, codeAB:'6191575102115', heigPhoto:'190', widtPhoto:'100'},
                {id:14, simple:true , dim:3, codeAB:'6191575101811', heigPhoto:'190', widtPhoto:'100'},
                {id:15, simple:true , dim:3, codeAB:'6191575101897', heigPhoto:'190', widtPhoto:'100'},
                {id:16, simple:true , dim:3, codeAB:'6191575100234', heigPhoto:'190', widtPhoto:'100'},
    ]},
    {id:32, page:33, genre:'BONNA', color:'#0a6603', articles:[
                {id:1, simple:true , dim:3, codeAB:'6191575100388', heigPhoto:'190', widtPhoto:'100'},
                {id:2, simple:true , dim:3, codeAB:'6191575101699', heigPhoto:'190', widtPhoto:'100'},
                {id:3, simple:true , dim:3, codeAB:'6191575101712', heigPhoto:'190', widtPhoto:'100'},
                // {id:4, simple:true , dim:3, codeAB:'6191575102047', heigPhoto:'190', widtPhoto:'100'},
                // {id:5, simple:true , dim:3, codeAB:'6191575102061', heigPhoto:'190', widtPhoto:'100'},
                // {id:6, simple:true , dim:3, codeAB:'6191575100463', heigPhoto:'190', widtPhoto:'100'},
                // {id:7, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'190', widtPhoto:'100'},
                // {id:8, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'190', widtPhoto:'100'},
                // {id:9, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'190', widtPhoto:'100'},
                // {id:10, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'190', widtPhoto:'100'},
                // {id:11, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'190', widtPhoto:'100'},
                // {id:12, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'190', widtPhoto:'100'},
                // {id:13, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'190', widtPhoto:'100'},
                // {id:14, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'190', widtPhoto:'100'},
                // {id:15, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'190', widtPhoto:'100'},
                // {id:16, simple:true , dim:3, codeAB:'6191575100517', heigPhoto:'190', widtPhoto:'100'},
    ]},
    {id:33, page:34, genre: "CAFE", color: "#2c302c", articles: [
                { id: 1, simple: true, dim: 3, codeAB: "6191467800136", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "8431610020874", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191467800129", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191593800567", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "8431610021086", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "8901036122419", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191467800037", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "8431610020911", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "7613033634109", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "7613033332883", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "8431610021079", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "8431610020898", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "8431610021543", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191593800550", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "843161002146", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "8431610020928", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id:34, page:35, genre: "CAFE", color: "#2c302c", articles: [
                { id: 1, simple: true, dim: 3, codeAB: "8431610020881", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191467800068", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191467800211", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191557800015", heigPhoto: "190", widtPhoto: "100"},
                { id: 1, simple: true, dim: 3, codeAB: "6191557800022", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
                // { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id:35, page:36, genre: "DIVERS", color: "#de1077", articles: [
            { id: 1, simple: true, dim: 3, codeAB: "6191300100072", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191300100089", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191300100065", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6192003306044", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "1049", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194061500372", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194061500020", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194061500440", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194061500426", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194061500037", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194061500013", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6917790980228", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6917790976269", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194061500433", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6111245370248", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6111245370156", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id:36, page:37, genre: "DIVERS", color: "#93179c", articles: [
            { id: 1, simple: true, dim: 3, codeAB: "8851123229883", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194001800111", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191525200267", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194001800722", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194001800524", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194001801569", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "90338052", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194019604244", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194001801095", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194007850042", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "5449000226662", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191435700055", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191435700246", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "611301", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "1053", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191534602205", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id:37, page:38, genre: "DIVERS", color: "#fa3c07", articles: [
            { id: 1, simple: true, dim: 3, codeAB: "6194017500227", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194017507400", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194017506311", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "2024", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "2021", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "2004", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "2003", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "2001", heigPhoto: "190", widtPhoto: "100"},
        //     { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
        //     { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
        //     { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
        //     { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
        //     { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
        //     { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
        //     { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
        //     { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id:38, page:39, genre: "DIVERS", color: "#10ada0", articles: [
            { id: 1, simple: true, dim: 3, codeAB: "6191402900129", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191402901287", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191402900518", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191402901270", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191402900426", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191402900082", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194049402230", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194000802284", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194000802277", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194000802352", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191402900389", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6191402900396", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194000802314", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194000802383", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194000802000", heigPhoto: "190", widtPhoto: "100"},
            { id: 1, simple: true, dim: 3, codeAB: "6194049402803", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id:39, page:40, genre: "DIVERS", color: "#c92a4a", articles: [
        //     { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
        //     { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
        //     { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
        //     { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
        //     { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
        //     { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
        //     { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
        //     { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
        //     { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
        //     { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
        //     { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
        //     { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
        //     { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
        //     { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
        //     { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"},
        //     { id: 1, simple: true, dim: 3, codeAB: "6191513502212", heigPhoto: "190", widtPhoto: "100"}
    ]},
]
export default AlimDefaultCateg