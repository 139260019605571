const CosmoDefaultCateg = [
    {id: 0, page: 1, genre: "INITIALE", color: "#9430cf", articles: [
        {id: 1, simple: true, dim: 3, codeAB: "7322540760095", heigPhoto: "500", widtPhoto: "80" }
    ]},
    {id: 1, page: 2, genre: "LIBERO PEAUDOUCE", color: "#67adf5", articles: [
            {id: 1, simple: true, dim: 3, codeAB: "7322540839333", heigPhoto: "190", widtPhoto: "80"},
            {id: 2, simple: true, dim: 3, codeAB: "6192400644756", heigPhoto: "190", widtPhoto: "80"},
            {id: 3, simple: true, dim: 3, codeAB: "6192400644763", heigPhoto: "190", widtPhoto: "80"},
            {id: 4, simple: true, dim: 3, codeAB: "6192400644770", heigPhoto: "190", widtPhoto: "80"},
            {id: 5, simple: true, dim: 3, codeAB: "6192400644787", heigPhoto: "190", widtPhoto: "80"},
            {id: 6, simple: true, dim: 3, codeAB: "6192400644787", heigPhoto: "190", widtPhoto: "80"},
            {id: 7, simple: true, dim: 3, codeAB: "6192400644794", heigPhoto: "190", widtPhoto: "80"},
            {id: 8, simple: true, dim: 3, codeAB: "6192400682376", heigPhoto: "190", widtPhoto: "80"},
            {id: 9, simple: true, dim: 3, codeAB: "6192400682390", heigPhoto: "190", widtPhoto: "80"},
            {id: 10, simple: true, dim: 3, codeAB: "6192400682383", heigPhoto: "190", widtPhoto: "80"},
            {id: 11, simple: true, dim: 3, codeAB: "6192400682802", heigPhoto: "190", widtPhoto: "80"},
            {id: 12, simple: true, dim: 3, codeAB: "6192400682765", heigPhoto: "190", widtPhoto: "80"},
            {id: 13, simple: true, dim: 3, codeAB: "6192400682437", heigPhoto: "190", widtPhoto: "80"},
            {id: 14, simple: true, dim: 3, codeAB: "6192400682413", heigPhoto: "190", widtPhoto: "80"},
            {id: 15, simple: true, dim: 3, codeAB: "6192400682420", heigPhoto: "190", widtPhoto: "80"},
            {id: 16, simple: true, dim: 3, codeAB: "6192400644367", heigPhoto: "190", widtPhoto: "80"}
    ]},
    {id: 1, page: 3, genre: "TENA & NANA", color: "#1c46ba", articles: [
            {id: 1, simple: true, dim: 3, codeAB: "8699114503228", heigPhoto: "190", widtPhoto: "80"},
            {id: 2, simple: true, dim: 3, codeAB: "8699114502528", heigPhoto: "190", widtPhoto: "80"},
            {id: 3, simple: true, dim: 3, codeAB: "7322540760071", heigPhoto: "190", widtPhoto: "80"},
            {id: 4, simple: true, dim: 3, codeAB: "7322540760095", heigPhoto: "190", widtPhoto: "80"},
            {id: 5, simple: true, dim: 3, codeAB: "6192400661739", heigPhoto: "190", widtPhoto: "80"},
            {id: 6, simple: true, dim: 3, codeAB: "6192400661562", heigPhoto: "190", widtPhoto: "80"},
            {id: 7, simple: true, dim: 3, codeAB: "6192400661746", heigPhoto: "190", widtPhoto: "80"},
            {id: 8, simple: true, dim: 3, codeAB: "6192400661579", heigPhoto: "190", widtPhoto: "80"},
            {id: 9, simple: true, dim: 3, codeAB: "16192400661668", heigPhoto: "190", widtPhoto: "80"},
            {id: 10, simple: true, dim: 3, codeAB: "7322540388510", heigPhoto: "190", widtPhoto: "80"},
            {id: 11, simple: true, dim: 3, codeAB: "7322540080049", heigPhoto: "190", widtPhoto: "80"},
            {id: 12, simple: true, dim: 3, codeAB: "6192400661722", heigPhoto: "190", widtPhoto: "80"},
            // {id: 13, simple: true, dim: 3, codeAB: "16192400661668", heigPhoto: "190", widtPhoto: "80"},
            // {id: 14, simple: true, dim: 3, codeAB: "16192400661668", heigPhoto: "190", widtPhoto: "80"},
            // {id: 15, simple: true, dim: 3, codeAB: "16192400661668", heigPhoto: "190", widtPhoto: "80"},
            // {id: 16, simple: true, dim: 3, codeAB: "16192400661668", heigPhoto: "190", widtPhoto: "80"}
    ]},
    {id: 2, page: 4, genre: "LOTUS", color: "#1b5fa6", articles: [
            {id: 1, simple: true, dim: 3, codeAB: "6192400660831", heigPhoto: "190", widtPhoto: "80"},
            {id: 2, simple: true, dim: 3, codeAB: "6192400682277", heigPhoto: "190", widtPhoto: "80"},
            {id: 3, simple: true, dim: 3, codeAB: "6192400661395", heigPhoto: "190", widtPhoto: "80"},
            {id: 4, simple: true, dim: 3, codeAB: "6192400661418", heigPhoto: "190", widtPhoto: "80"},
            {id: 5, simple: true, dim: 3, codeAB: "6192400660312", heigPhoto: "190", widtPhoto: "80"},
            {id: 6, simple: true, dim: 3, codeAB: "6192400660343", heigPhoto: "190", widtPhoto: "80"},
            {id: 7, simple: true, dim: 3, codeAB: "6192400660305", heigPhoto: "190", widtPhoto: "80"},
            {id: 8, simple: true, dim: 3, codeAB: "6192400661340", heigPhoto: "190", widtPhoto: "80"},
            {id: 9, simple: true, dim: 3, codeAB: "6192400661364", heigPhoto: "190", widtPhoto: "80"},
            {id: 10, simple: true, dim: 3, codeAB: "6192400661494", heigPhoto: "190", widtPhoto: "80"},
            {id: 11, simple: true, dim: 3, codeAB: "6192400661425", heigPhoto: "190", widtPhoto: "80"},
            {id: 12, simple: true, dim: 3, codeAB: "6192400660435", heigPhoto: "190", widtPhoto: "80"},
            {id: 13, simple: true, dim: 3, codeAB: "6192400660398", heigPhoto: "190", widtPhoto: "80"},
            {id: 14, simple: true, dim: 3, codeAB: "6192400660831", heigPhoto: "190", widtPhoto: "80"},
            {id: 15, simple: true, dim: 3, codeAB: "6192400660947", heigPhoto: "190", widtPhoto: "80"},
            {id: 16, simple: true, dim: 3, codeAB: "6192000584186", heigPhoto: "190", widtPhoto: "80"}
    ]},
    {id: 2, page: 5, genre: "SENSEA", color: "#e5f567", articles: [
            {id: 1, simple: true, dim: 3, codeAB: "6192000517122", heigPhoto: "190", widtPhoto: "80"},
            {id: 2, simple: true, dim: 3, codeAB: "6192000583745", heigPhoto: "190", widtPhoto: "80"},
            {id: 3, simple: true, dim: 3, codeAB: "16192000519222", heigPhoto: "190", widtPhoto: "80"},
            {id: 4, simple: true, dim: 3, codeAB: "6192000515081", heigPhoto: "190", widtPhoto: "80"},
            {id: 5, simple: true, dim: 3, codeAB: "6192000516002", heigPhoto: "190", widtPhoto: "80"},
            {id: 6, simple: true, dim: 3, codeAB: "6192000515340", heigPhoto: "190", widtPhoto: "80"},
            {id: 7, simple: true, dim: 3, codeAB: "6192000512127", heigPhoto: "190", widtPhoto: "80"},
            {id: 8, simple: true, dim: 3, codeAB: "6192000512110", heigPhoto: "190", widtPhoto: "80"},
            {id: 9, simple: true, dim: 3, codeAB: "6192000502777", heigPhoto: "190", widtPhoto: "80"},
            {id: 10, simple: true, dim: 3, codeAB: "16192000502804", heigPhoto: "190", widtPhoto: "80"},
            {id: 11, simple: true, dim: 3, codeAB: "16192000583889", heigPhoto: "190", widtPhoto: "80"},
            {id: 12, simple: true, dim: 3, codeAB: "6192000583806", heigPhoto: "190", widtPhoto: "80"},
            {id: 13, simple: true, dim: 3, codeAB: "6192000584124", heigPhoto: "190", widtPhoto: "80"},
            {id: 14, simple: true, dim: 3, codeAB: "6192000583929", heigPhoto: "190", widtPhoto: "80"},
            {id: 15, simple: true, dim: 3, codeAB: "6192000502937", heigPhoto: "190", widtPhoto: "80"},
            {id: 16, simple: true, dim: 3, codeAB: "6192000583820", heigPhoto: "190", widtPhoto: "80"}
    ]},
    {id: 1, page: 6, genre: "JUDY", color: "#e2fa0c", articles: [
            {id: 1, simple: true, dim: 3, codeAB: "6192002403140", heigPhoto: "190", widtPhoto: "80"},
            {id: 2, simple: true, dim: 3, codeAB: "6192002403133", heigPhoto: "190", widtPhoto: "80"},
            {id: 3, simple: true, dim: 3, codeAB: "6192002403294", heigPhoto: "190", widtPhoto: "80"},
            {id: 4, simple: true, dim: 3, codeAB: "6192002402129", heigPhoto: "190", widtPhoto: "80"},
            {id: 5, simple: true, dim: 3, codeAB: "6192002402105", heigPhoto: "190", widtPhoto: "80"},
            {id: 6, simple: true, dim: 3, codeAB: "6192002402488", heigPhoto: "190", widtPhoto: "80"},
            {id: 7, simple: true, dim: 3, codeAB: "6192002406233", heigPhoto: "190", widtPhoto: "80"},
            {id: 8, simple: true, dim: 3, codeAB: "6192002401740", heigPhoto: "190", widtPhoto: "80"},
            {id: 9, simple: true, dim: 3, codeAB: "6192002406189", heigPhoto: "190", widtPhoto: "80"},
            {id: 10, simple: true, dim: 3, codeAB: "6192002402266", heigPhoto: "190", widtPhoto: "80"},
            {id: 11, simple: true, dim: 3, codeAB: "6192002450113", heigPhoto: "190", widtPhoto: "80"},
            {id: 12, simple: true, dim: 3, codeAB: "6192002403638", heigPhoto: "190", widtPhoto: "80"},
            {id: 13, simple: true, dim: 3, codeAB: "6192002402204", heigPhoto: "190", widtPhoto: "80"},
            {id: 14, simple: true, dim: 3, codeAB: "6192002402143", heigPhoto: "190", widtPhoto: "80"},
            {id: 15, simple: true, dim: 3, codeAB: "6192002408152", heigPhoto: "190", widtPhoto: "80"},
            {id: 16, simple: true, dim: 3, codeAB: "6130552002772", heigPhoto: "190", widtPhoto: "80"}
    ]},
    {id: 1, page: 7, genre: "JUDY", color: "#e2fa0c", articles: [
            {id: 1, simple: true, dim: 3, codeAB: "6130552001635", heigPhoto: "190", widtPhoto: "80"},
            {id: 2, simple: true, dim: 3, codeAB: "6192002406523", heigPhoto: "190", widtPhoto: "80"},
            {id: 3, simple: true, dim: 3, codeAB: "6192002402235", heigPhoto: "190", widtPhoto: "80"},
            {id: 4, simple: true, dim: 3, codeAB: "6192002402815", heigPhoto: "190", widtPhoto: "80"},
            {id: 5, simple: true, dim: 3, codeAB: "6192002402280", heigPhoto: "190", widtPhoto: "80"},
            {id: 6, simple: true, dim: 3, codeAB: "6192002406394", heigPhoto: "190", widtPhoto: "80"},
            {id: 7, simple: true, dim: 3, codeAB: "6192002408008", heigPhoto: "190", widtPhoto: "80"},
            {id: 8, simple: true, dim: 3, codeAB: "6192002408480", heigPhoto: "190", widtPhoto: "80"},
            {id: 9, simple: true, dim: 3, codeAB: "6192002408565", heigPhoto: "190", widtPhoto: "80"},
            {id: 10, simple: true, dim: 3, codeAB: "6192002406721", heigPhoto: "190", widtPhoto: "80"},
            {id: 11, simple: true, dim: 3, codeAB: "6192002406400", heigPhoto: "190", widtPhoto: "80"},
            {id: 12, simple: true, dim: 3, codeAB: "6192002492496", heigPhoto: "190", widtPhoto: "80"},
            {id: 13, simple: true, dim: 3, codeAB: "6192002408329", heigPhoto: "190", widtPhoto: "80"},
            {id: 14, simple: true, dim: 3, codeAB: "6192002401733", heigPhoto: "190", widtPhoto: "80"},
            {id: 15, simple: true, dim: 3, codeAB: "6192002401740", heigPhoto: "190", widtPhoto: "80"},
            {id: 16, simple: true, dim: 3, codeAB: "6192002401795", heigPhoto: "190", widtPhoto: "80"}
    ]},
    {id: 2, page: 8, genre: "NIHEL", color: "#003480", articles: [
            {id: 1, simple: true, dim: 3, codeAB: "6192017319757", heigPhoto: "190", widtPhoto: "100"},
            {id: 2, simple: true, dim: 3, codeAB: "6192017320784", heigPhoto: "190", widtPhoto: "100"},
            {id: 3, simple: true, dim: 3, codeAB: "6192017320777", heigPhoto: "190", widtPhoto: "100"},
            {id: 4, simple: true, dim: 3, codeAB: "6192017300885", heigPhoto: "190", widtPhoto: "100"},
            {id: 5, simple: true, dim: 3, codeAB: "6192017300908", heigPhoto: "190", widtPhoto: "100"},
            //{id: 6, simple: true, dim: 3, codeAB: "6192017300878", heigPhoto: "190", widtPhoto: "100"},
            {id: 7, simple: true, dim: 3, codeAB: "6192017317333", heigPhoto: "190", widtPhoto: "100"},
            {id: 8, simple: true, dim: 3, codeAB: "6192017317364", heigPhoto: "190", widtPhoto: "100"},
            {id: 9, simple: true, dim: 3, codeAB: "6192017300472", heigPhoto: "190", widtPhoto: "100"},
            {id: 10, simple: true, dim: 3, codeAB: "6192017314295", heigPhoto: "190", widtPhoto: "100"},
            {id: 11, simple: true, dim: 3, codeAB: "6192017300489", heigPhoto: "190", widtPhoto: "100"},
            {id: 12, simple: true, dim: 3, codeAB: "6192017317258", heigPhoto: "190", widtPhoto: "100"},
            {id: 13, simple: true, dim: 3, codeAB: "6192017334088", heigPhoto: "190", widtPhoto: "100"},
            {id: 14, simple: true, dim: 3, codeAB: "6192017334286", heigPhoto: "190", widtPhoto: "100"},
            //{id: 15, simple: true, dim: 3, codeAB: "6192017300441", heigPhoto: "190", widtPhoto: "100"},
            {id: 16, simple: true, dim: 3, codeAB: "6192017329695", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id: 2, page: 9, genre: "NIHEL", color: "#003480", articles: [
            {id: 1, simple: true, dim: 3, codeAB: "6192017321446", heigPhoto: "190", widtPhoto: "100"},
            {id: 2, simple: true, dim: 3, codeAB: "6192017321484", heigPhoto: "190", widtPhoto: "100"},
            {id: 3, simple: true, dim: 3, codeAB: "6192017335030", heigPhoto: "190", widtPhoto: "100"},
            {id: 4, simple: true, dim: 3, codeAB: "6192017339007", heigPhoto: "190", widtPhoto: "100"},
            {id: 5, simple: true, dim: 3, codeAB: "6192017336341", heigPhoto: "190", widtPhoto: "100"},
            {id: 6, simple: true, dim: 3, codeAB: "6192017315766", heigPhoto: "190", widtPhoto: "100"},
            {id: 7, simple: true, dim: 3, codeAB: "6192017338741", heigPhoto: "190", widtPhoto: "100"},
            {id: 8, simple: true, dim: 3, codeAB: "6192017316350", heigPhoto: "190", widtPhoto: "100"},
            {id: 9, simple: true, dim: 3, codeAB: "6192017338994", heigPhoto: "190", widtPhoto: "100"},
            {id: 10, simple: true, dim: 3, codeAB: "6192017301028", heigPhoto: "190", widtPhoto: "100"},
            {id: 11, simple: true, dim: 3, codeAB: "6192017320876", heigPhoto: "190", widtPhoto: "100"},
            {id: 12, simple: true, dim: 3, codeAB: "6192017328308", heigPhoto: "190", widtPhoto: "100"},
            {id: 13, simple: true, dim: 3, codeAB: "6192017306092", heigPhoto: "190", widtPhoto: "100"},
            {id: 14, simple: true, dim: 3, codeAB: "6192017321934", heigPhoto: "190", widtPhoto: "100"},
            {id: 15, simple: true, dim: 3, codeAB: "6192017328285", heigPhoto: "190", widtPhoto: "100"},
            {id: 16, simple: true, dim: 3, codeAB: "6192017321477", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id: 2, page: 10, genre: "ROJANET", color: "#0f2477", articles: [
            {id: 1, simple: true, dim: 3, codeAB: "6192017316022", heigPhoto: "190", widtPhoto: "100"},
            {id: 2, simple: true, dim: 3, codeAB: "6192017319306", heigPhoto: "190", widtPhoto: "100"},
            {id: 3, simple: true, dim: 3, codeAB: "6192017319344", heigPhoto: "190", widtPhoto: "100"},
            {id: 4, simple: true, dim: 3, codeAB: "6192017315865", heigPhoto: "190", widtPhoto: "100"},
            {id: 5, simple: true, dim: 3, codeAB: "6192017316947", heigPhoto: "190", widtPhoto: "100"},
            {id: 6, simple: true, dim: 3, codeAB: "6192017316978", heigPhoto: "190", widtPhoto: "100"},
            {id: 7, simple: true, dim: 3, codeAB: "6192017317616", heigPhoto: "190", widtPhoto: "100"},
            {id: 8, simple: true, dim: 3, codeAB: "6192017316046", heigPhoto: "190", widtPhoto: "100"},
            {id: 9, simple: true, dim: 3, codeAB: "6192017315896", heigPhoto: "190", widtPhoto: "100"},
            {id: 10, simple: true, dim: 3, codeAB: "6192017315988", heigPhoto: "190", widtPhoto: "100"},
            {id: 11, simple: true, dim: 3, codeAB: "6192017316343", heigPhoto: "190", widtPhoto: "100"},
            {id: 12, simple: true, dim: 3, codeAB: "6192017323105", heigPhoto: "190", widtPhoto: "100"},
            {id: 13, simple: true, dim: 3, codeAB: "6192017320913", heigPhoto: "190", widtPhoto: "100"},
            {id: 14, simple: true, dim: 3, codeAB: "6192017317524", heigPhoto: "190", widtPhoto: "100"},
            {id: 15, simple: true, dim: 3, codeAB: "6192017331612", heigPhoto: "190", widtPhoto: "100"},
            {id: 16, simple: true, dim: 3, codeAB: "6192017317371", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id: 2, page: 11, genre: "ROJANET", color: "#0f2477", articles: [
            {id: 1, simple: true, dim: 3, codeAB: "6192017330417", heigPhoto: "190", widtPhoto: "100"},
            {id: 2, simple: true, dim: 3, codeAB: "6192017322177", heigPhoto: "190", widtPhoto: "100"},
            {id: 3, simple: true, dim: 3, codeAB: "6192017336518", heigPhoto: "190", widtPhoto: "100"},
            //{id: 4, simple: true, dim: 3, codeAB: "6192017336518", heigPhoto: "190", widtPhoto: "100"},
            {id: 5, simple: true, dim: 3, codeAB: "6192017320029", heigPhoto: "190", widtPhoto: "100"},
            {id: 6, simple: true, dim: 3, codeAB: "6192017315841", heigPhoto: "190", widtPhoto: "100"},
            {id: 7, simple: true, dim: 3, codeAB: "6192017317531", heigPhoto: "190", widtPhoto: "100"},
            {id: 8, simple: true, dim: 3, codeAB: "6192017317586", heigPhoto: "190", widtPhoto: "100"},
            // {id: 9, simple: true, dim: 3, codeAB: "6192017317555", heigPhoto: "190", widtPhoto: "100"},
            // {id: 10, simple: true, dim: 3, codeAB: "6192017330417", heigPhoto: "190", widtPhoto: "100"},
            // {id: 11, simple: true, dim: 3, codeAB: "6192017322177", heigPhoto: "190", widtPhoto: "100"},
            // {id: 12, simple: true, dim: 3, codeAB: "6192017336518", heigPhoto: "190", widtPhoto: "100"},
            // {id: 13, simple: true, dim: 3, codeAB: "6192017317586", heigPhoto: "190", widtPhoto: "100"},
            // {id: 14, simple: true, dim: 3, codeAB: "6192017317531", heigPhoto: "190", widtPhoto: "100"},
            // {id: 15, simple: true, dim: 3, codeAB: "6192017317555", heigPhoto: "190", widtPhoto: "100"},
            // {id: 16, simple: true, dim: 3, codeAB: "6192017315841", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id: 2, page: 12, genre: "SOLYSS", color: "#1a1a1a", articles: [
            {id: 1, simple: true, dim: 3, codeAB: "4015000944052", heigPhoto: "190", widtPhoto: "100"},
            // {id: 2, simple: true, dim: 3, codeAB: "4015000944052", heigPhoto: "190", widtPhoto: "100"},
            // {id: 3, simple: true, dim: 3, codeAB: "4015000944052", heigPhoto: "190", widtPhoto: "100"},
            // {id: 4, simple: true, dim: 3, codeAB: "4015000944052", heigPhoto: "190", widtPhoto: "100"},
            // {id: 5, simple: true, dim: 3, codeAB: "4015000944052", heigPhoto: "190", widtPhoto: "100"},
            // {id: 6, simple: true, dim: 3, codeAB: "4015000944052", heigPhoto: "190", widtPhoto: "100"},
            // {id: 7, simple: true, dim: 3, codeAB: "4015000944052", heigPhoto: "190", widtPhoto: "100"},
            // {id: 8, simple: true, dim: 3, codeAB: "4015000944052", heigPhoto: "190", widtPhoto: "100"},
            // {id: 9, simple: true, dim: 3, codeAB: "4015000944052", heigPhoto: "190", widtPhoto: "100"},
            // {id: 10, simple: true, dim: 3, codeAB: "4015000944052", heigPhoto: "190", widtPhoto: "100"},
            // {id: 11, simple: true, dim: 3, codeAB: "4015000944052", heigPhoto: "190", widtPhoto: "100"},
            // {id: 12, simple: true, dim: 3, codeAB: "4015000944052", heigPhoto: "190", widtPhoto: "100"},
            // {id: 13, simple: true, dim: 3, codeAB: "4015000944052", heigPhoto: "190", widtPhoto: "100"},
            // {id: 14, simple: true, dim: 3, codeAB: "4015000944052", heigPhoto: "190", widtPhoto: "100"},
            // {id: 15, simple: true, dim: 3, codeAB: "4015000944052", heigPhoto: "190", widtPhoto: "100"},
            // {id: 16, simple: true, dim: 3, codeAB: "4015000944052", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id: 2, page: 13, genre: "SUNSILK", color: "#e12875", articles: [
        {id: 1, simple: true, dim: 3, codeAB: "6192000752950", heigPhoto: "190", widtPhoto: "100"},
        {id: 2, simple: true, dim: 3, codeAB: "6192000753223", heigPhoto: "190", widtPhoto: "100"},
        // {id: 3, simple: true, dim: 3, codeAB: "6192000752950", heigPhoto: "190", widtPhoto: "100"},
        // {id: 4, simple: true, dim: 3, codeAB: "6192000752950", heigPhoto: "190", widtPhoto: "100"},
        // {id: 5, simple: true, dim: 3, codeAB: "6192000752950", heigPhoto: "190", widtPhoto: "100"},
        // {id: 6, simple: true, dim: 3, codeAB: "6192000752950", heigPhoto: "190", widtPhoto: "100"},
        // {id: 7, simple: true, dim: 3, codeAB: "6192000752950", heigPhoto: "190", widtPhoto: "100"},
        // {id: 8, simple: true, dim: 3, codeAB: "6192000752950", heigPhoto: "190", widtPhoto: "100"},
        // {id: 9, simple: true, dim: 3, codeAB: "6192000752950", heigPhoto: "190", widtPhoto: "100"},
        // {id: 10, simple: true, dim: 3, codeAB: "6192000752950", heigPhoto: "190", widtPhoto: "100"},
        // {id: 11, simple: true, dim: 3, codeAB: "6192000752950", heigPhoto: "190", widtPhoto: "100"},
        // {id: 12, simple: true, dim: 3, codeAB: "6192000752950", heigPhoto: "190", widtPhoto: "100"},
        // {id: 13, simple: true, dim: 3, codeAB: "6192000752950", heigPhoto: "190", widtPhoto: "100"},
        // {id: 14, simple: true, dim: 3, codeAB: "6192000752950", heigPhoto: "190", widtPhoto: "100"},
        // {id: 15, simple: true, dim: 3, codeAB: "6192000752950", heigPhoto: "190", widtPhoto: "100"},
        // {id: 16, simple: true, dim: 3, codeAB: "6192000752950", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id: 2, page: 14, genre: "SIGNAL", color: "#2a316b", articles: [
        {id: 1, simple: true, dim: 3, codeAB: "6221048403248", heigPhoto: "190", widtPhoto: "100"},
        {id: 2, simple: true, dim: 3, codeAB: "6221155061850", heigPhoto: "190", widtPhoto: "100"},
        {id: 3, simple: true, dim: 3, codeAB: "8717163738542", heigPhoto: "190", widtPhoto: "100"},
        {id: 4, simple: true, dim: 3, codeAB: "8710522417379", heigPhoto: "190", widtPhoto: "100"},
        // {id: 5, simple: true, dim: 3, codeAB: "8717163738559", heigPhoto: "190", widtPhoto: "100"},
        // {id: 6, simple: true, dim: 3, codeAB: "6221048407772", heigPhoto: "190", widtPhoto: "100"},
        // {id: 7, simple: true, dim: 3, codeAB: "6221048409707", heigPhoto: "190", widtPhoto: "100"},
        // {id: 8, simple: true, dim: 3, codeAB: "6111026117925", heigPhoto: "190", widtPhoto: "100"},
        // {id: 1, simple: true, dim: 3, codeAB: "6221048403248", heigPhoto: "190", widtPhoto: "100"},
        // {id: 2, simple: true, dim: 3, codeAB: "6221155061850", heigPhoto: "190", widtPhoto: "100"},
        // {id: 3, simple: true, dim: 3, codeAB: "8717163738542", heigPhoto: "190", widtPhoto: "100"},
        // {id: 4, simple: true, dim: 3, codeAB: "8710522417379", heigPhoto: "190", widtPhoto: "100"},
        // {id: 5, simple: true, dim: 3, codeAB: "8717163738559", heigPhoto: "190", widtPhoto: "100"},
        // {id: 6, simple: true, dim: 3, codeAB: "6221048407772", heigPhoto: "190", widtPhoto: "100"},
        // {id: 7, simple: true, dim: 3, codeAB: "6221048409707", heigPhoto: "190", widtPhoto: "100"},
        // {id: 8, simple: true, dim: 3, codeAB: "6111026117925", heigPhoto: "190", widtPhoto: "100"},
    ]},
    {id: 2, page: 15, genre: "SIGNAL", color: "#2a316b", articles: [
        // {id: 1, simple: true, dim: 3, codeAB: "6221048403248", heigPhoto: "190", widtPhoto: "100"},
        // {id: 2, simple: true, dim: 3, codeAB: "6221155061850", heigPhoto: "190", widtPhoto: "100"},
        // {id: 3, simple: true, dim: 3, codeAB: "8717163738542", heigPhoto: "190", widtPhoto: "100"},
        //{id: 4, simple: true, dim: 3, codeAB: "8710522417379", heigPhoto: "190", widtPhoto: "100"},
        {id: 5, simple: true, dim: 3, codeAB: "8717163738559", heigPhoto: "190", widtPhoto: "100"},
        {id: 6, simple: true, dim: 3, codeAB: "6221048407772", heigPhoto: "190", widtPhoto: "100"},
        {id: 7, simple: true, dim: 3, codeAB: "6221048409707", heigPhoto: "190", widtPhoto: "100"},
        {id: 8, simple: true, dim: 3, codeAB: "6111026117925", heigPhoto: "190", widtPhoto: "100"},
        // {id: 1, simple: true, dim: 3, codeAB: "6221048403248", heigPhoto: "190", widtPhoto: "100"},
        // {id: 2, simple: true, dim: 3, codeAB: "6221155061850", heigPhoto: "190", widtPhoto: "100"},
        // {id: 3, simple: true, dim: 3, codeAB: "8717163738542", heigPhoto: "190", widtPhoto: "100"},
        // {id: 4, simple: true, dim: 3, codeAB: "8710522417379", heigPhoto: "190", widtPhoto: "100"},
        // {id: 5, simple: true, dim: 3, codeAB: "8717163738559", heigPhoto: "190", widtPhoto: "100"},
        // {id: 6, simple: true, dim: 3, codeAB: "6221048407772", heigPhoto: "190", widtPhoto: "100"},
        // {id: 7, simple: true, dim: 3, codeAB: "6221048409707", heigPhoto: "190", widtPhoto: "100"},
        // {id: 8, simple: true, dim: 3, codeAB: "6111026117925", heigPhoto: "190", widtPhoto: "100"},
    ]},
    {id: 2, page: 16, genre: "COLGATE", color: "#f5010b", articles: [
        {id: 2, simple: true, dim: 3, codeAB: "8718951289512", heigPhoto: "190", widtPhoto: "100"},
        {id: 3, simple: true, dim: 3, codeAB: "6920354811654", heigPhoto: "190", widtPhoto: "100"},
        {id: 4, simple: true, dim: 3, codeAB: "6281001112051", heigPhoto: "190", widtPhoto: "100"},
        {id: 5, simple: true, dim: 3, codeAB: "6920354827211", heigPhoto: "190", widtPhoto: "100"},
        {id: 6, simple: true, dim: 3, codeAB: "6001067524441", heigPhoto: "190", widtPhoto: "100"},
        {id: 7, simple: true, dim: 3, codeAB: "6001067022558", heigPhoto: "190", widtPhoto: "100"},
        {id: 8, simple: true, dim: 3, codeAB: "8718951412057", heigPhoto: "190", widtPhoto: "100"},
        {id: 9, simple: true, dim: 3, codeAB: "8850006326305", heigPhoto: "190", widtPhoto: "100"},
        {id: 10, simple: true, dim: 3, codeAB: "6281001112044", heigPhoto: "190", widtPhoto: "100"},
        {id: 11, simple: true, dim: 3, codeAB: "6001067521600", heigPhoto: "190", widtPhoto: "100"},
        {id: 12, simple: true, dim: 3, codeAB: "7891024133545", heigPhoto: "190", widtPhoto: "100"},
        {id: 14, simple: true, dim: 3, codeAB: "6281001112037", heigPhoto: "190", widtPhoto: "100"},
        {id: 15, simple: true, dim: 3, codeAB: "16920354880534", heigPhoto: "190", widtPhoto: "100"},
        {id: 2, simple: true, dim: 3, codeAB: "6920354826207", heigPhoto: "190", widtPhoto: "100"},
        {id: 3, simple: true, dim: 3, codeAB: "6920354826214", heigPhoto: "190", widtPhoto: "100"},
        {id: 4, simple: true, dim: 3, codeAB: "8718951288911", heigPhoto: "190", widtPhoto: "100"},
        
    ]},
    {id: 2, page: 17, genre: "COLGATE", color: "#f5010b", articles: [
        {id: 1, simple: true, dim: 3, codeAB: "8714789823775", heigPhoto: "190", widtPhoto: "100"},
        {id: 16, simple: true, dim: 3, codeAB: "6001067875093", heigPhoto: "190", widtPhoto: "100"},
        {id: 1, simple: true, dim: 3, codeAB: "6001067501480", heigPhoto: "190", widtPhoto: "100"},
        {id: 13, simple: true, dim: 3, codeAB: "6001067007289", heigPhoto: "190", widtPhoto: "100"},
        // {id: 1, simple: true, dim: 3, codeAB: "8714789823775", heigPhoto: "190", widtPhoto: "100"},
        // {id: 2, simple: true, dim: 3, codeAB: "6920354826207", heigPhoto: "190", widtPhoto: "100"},
        // {id: 3, simple: true, dim: 3, codeAB: "6920354826214", heigPhoto: "190", widtPhoto: "100"},
        // {id: 4, simple: true, dim: 3, codeAB: "8718951288911", heigPhoto: "190", widtPhoto: "100"},
        // {id: 1, simple: true, dim: 3, codeAB: "8714789823775", heigPhoto: "190", widtPhoto: "100"},
        // {id: 2, simple: true, dim: 3, codeAB: "6920354826207", heigPhoto: "190", widtPhoto: "100"},
        // {id: 3, simple: true, dim: 3, codeAB: "6920354826214", heigPhoto: "190", widtPhoto: "100"},
        // {id: 4, simple: true, dim: 3, codeAB: "8718951288911", heigPhoto: "190", widtPhoto: "100"},
        // {id: 1, simple: true, dim: 3, codeAB: "8714789823775", heigPhoto: "190", widtPhoto: "100"},
        // {id: 2, simple: true, dim: 3, codeAB: "6920354826207", heigPhoto: "190", widtPhoto: "100"},
        // {id: 3, simple: true, dim: 3, codeAB: "6920354826214", heigPhoto: "190", widtPhoto: "100"},
        // {id: 4, simple: true, dim: 3, codeAB: "8718951288911", heigPhoto: "190", widtPhoto: "100"},
    ]},
    {id: 2, page: 18, genre: "CHAMPOING", color: "#474a32", articles: [
        {id: 1, simple: true, dim: 3, codeAB: "6192013103602", heigPhoto: "190", widtPhoto: "100"},
        {id: 2, simple: true, dim: 3, codeAB: "6221155065087", heigPhoto: "190", widtPhoto: "100"},
        {id: 3, simple: true, dim: 3, codeAB: "6221155058409", heigPhoto: "190", widtPhoto: "100"},
        {id: 4, simple: true, dim: 3, codeAB: "6192430015762", heigPhoto: "190", widtPhoto: "100"},
        // {id: 5, simple: true, dim: 3, codeAB: "6192430015151", heigPhoto: "190", widtPhoto: "100"},
        // {id: 6, simple: true, dim: 3, codeAB: "6111056005636", heigPhoto: "190", widtPhoto: "100"},
        // {id: 7, simple: true, dim: 3, codeAB: "3610340370397", heigPhoto: "190", widtPhoto: "100"},
        // {id: 8, simple: true, dim: 3, codeAB: "6192013103602", heigPhoto: "190", widtPhoto: "100"},
        // {id: 9, simple: true, dim: 3, codeAB: "6192017335672", heigPhoto: "190", widtPhoto: "100"},
        // {id: 10, simple: true, dim: 3, codeAB: "6192017332923", heigPhoto: "190", widtPhoto: "100"},
        // {id: 1, simple: true, dim: 3, codeAB: "6192013103602", heigPhoto: "190", widtPhoto: "100"},
        // {id: 2, simple: true, dim: 3, codeAB: "6221155065087", heigPhoto: "190", widtPhoto: "100"},
        // {id: 3, simple: true, dim: 3, codeAB: "6221155058409", heigPhoto: "190", widtPhoto: "100"},
        // {id: 4, simple: true, dim: 3, codeAB: "6192430015762", heigPhoto: "190", widtPhoto: "100"},
        // {id: 5, simple: true, dim: 3, codeAB: "6192430015151", heigPhoto: "190", widtPhoto: "100"},
        // {id: 5, simple: true, dim: 3, codeAB: "6192430015151", heigPhoto: "190", widtPhoto: "100"},
    ]},
    {id: 2, page: 19, genre: "CHAMPOING", color: "#474a32", articles: [
        // {id: 1, simple: true, dim: 3, codeAB: "6192013103602", heigPhoto: "190", widtPhoto: "100"},
        // {id: 2, simple: true, dim: 3, codeAB: "6221155065087", heigPhoto: "190", widtPhoto: "100"},
        // {id: 3, simple: true, dim: 3, codeAB: "6221155058409", heigPhoto: "190", widtPhoto: "100"},
        // {id: 4, simple: true, dim: 3, codeAB: "6192430015762", heigPhoto: "190", widtPhoto: "100"},
        {id: 5, simple: true, dim: 3, codeAB: "6192430015151", heigPhoto: "190", widtPhoto: "100"},
        {id: 6, simple: true, dim: 3, codeAB: "6111056005636", heigPhoto: "190", widtPhoto: "100"},
        {id: 7, simple: true, dim: 3, codeAB: "3610340370397", heigPhoto: "190", widtPhoto: "100"},
        {id: 8, simple: true, dim: 3, codeAB: "6192013103602", heigPhoto: "190", widtPhoto: "100"},
        {id: 9, simple: true, dim: 3, codeAB: "6192017335672", heigPhoto: "190", widtPhoto: "100"},
        {id: 10, simple: true, dim: 3, codeAB: "6192017332923", heigPhoto: "190", widtPhoto: "100"},
        // {id: 1, simple: true, dim: 3, codeAB: "6192013103602", heigPhoto: "190", widtPhoto: "100"},
        // {id: 2, simple: true, dim: 3, codeAB: "6221155065087", heigPhoto: "190", widtPhoto: "100"},
        // {id: 3, simple: true, dim: 3, codeAB: "6221155058409", heigPhoto: "190", widtPhoto: "100"},
        // {id: 4, simple: true, dim: 3, codeAB: "6192430015762", heigPhoto: "190", widtPhoto: "100"},
        // {id: 5, simple: true, dim: 3, codeAB: "6192430015151", heigPhoto: "190", widtPhoto: "100"},
        // {id: 5, simple: true, dim: 3, codeAB: "6192430015151", heigPhoto: "190", widtPhoto: "100"},
    ]},
    {id: 2, page: 20, genre: "NADHIF", color: "#18b3e7", articles: [
        {id: 1, simple: true, dim: 3, codeAB: "6192430017162", heigPhoto: "190", widtPhoto: "100"},
        {id: 2, simple: true, dim: 3, codeAB: "6192430015694", heigPhoto: "190", widtPhoto: "100"},
        {id: 3, simple: true, dim: 3, codeAB: "6192430017155", heigPhoto: "190", widtPhoto: "100"},
        {id: 4, simple: true, dim: 3, codeAB: "6192430015670", heigPhoto: "190", widtPhoto: "100"},
        // {id: 5, simple: true, dim: 3, codeAB: "6130430002825", heigPhoto: "190", widtPhoto: "100"},
        {id: 6, simple: true, dim: 3, codeAB: "6192430017346", heigPhoto: "190", widtPhoto: "100"},
        {id: 7, simple: true, dim: 3, codeAB: "100", heigPhoto: "190", widtPhoto: "100"},
        {id: 8, simple: true, dim: 3, codeAB: "6192430016578", heigPhoto: "190", widtPhoto: "100"},
        // {id: 1, simple: true, dim: 3, codeAB: "6192430017162", heigPhoto: "190", widtPhoto: "100"},
        // {id: 2, simple: true, dim: 3, codeAB: "6192430015694", heigPhoto: "190", widtPhoto: "100"},
        // {id: 3, simple: true, dim: 3, codeAB: "6192430017155", heigPhoto: "190", widtPhoto: "100"},
        // {id: 4, simple: true, dim: 3, codeAB: "6192430015670", heigPhoto: "190", widtPhoto: "100"},
        // {id: 5, simple: true, dim: 3, codeAB: "6130430002825", heigPhoto: "190", widtPhoto: "100"},
        // {id: 6, simple: true, dim: 3, codeAB: "6192430017346", heigPhoto: "190", widtPhoto: "100"},
        // {id: 7, simple: true, dim: 3, codeAB: "100", heigPhoto: "190", widtPhoto: "100"},
        // {id: 8, simple: true, dim: 3, codeAB: "6192430016578", heigPhoto: "190", widtPhoto: "100"},
    ]},
    {id: 2, page: 21, genre: "SOFTY", color: "#a6083a", articles: [
        {id: 1, simple: true, dim: 3, codeAB: "6192436000762", heigPhoto: "190", widtPhoto: "100"},
        {id: 2, simple: true, dim: 3, codeAB: "6192436000403", heigPhoto: "190", widtPhoto: "100"},
        {id: 3, simple: true, dim: 3, codeAB: "6192436000076", heigPhoto: "190", widtPhoto: "100"},
        {id: 4, simple: true, dim: 3, codeAB: "6192436000137", heigPhoto: "190", widtPhoto: "100"},
        // {id: 1, simple: true, dim: 3, codeAB: "6192436000762", heigPhoto: "190", widtPhoto: "100"},
        // {id: 2, simple: true, dim: 3, codeAB: "6192436000403", heigPhoto: "190", widtPhoto: "100"},
        // {id: 3, simple: true, dim: 3, codeAB: "6192436000076", heigPhoto: "190", widtPhoto: "100"},
        // {id: 4, simple: true, dim: 3, codeAB: "6192436000137", heigPhoto: "190", widtPhoto: "100"},
        // {id: 1, simple: true, dim: 3, codeAB: "6192436000762", heigPhoto: "190", widtPhoto: "100"},
        // {id: 2, simple: true, dim: 3, codeAB: "6192436000403", heigPhoto: "190", widtPhoto: "100"},
        // {id: 3, simple: true, dim: 3, codeAB: "6192436000076", heigPhoto: "190", widtPhoto: "100"},
        // {id: 4, simple: true, dim: 3, codeAB: "6192436000137", heigPhoto: "190", widtPhoto: "100"},
        // {id: 1, simple: true, dim: 3, codeAB: "6192436000762", heigPhoto: "190", widtPhoto: "100"},
        // {id: 2, simple: true, dim: 3, codeAB: "6192436000403", heigPhoto: "190", widtPhoto: "100"},
        // {id: 3, simple: true, dim: 3, codeAB: "6192436000076", heigPhoto: "190", widtPhoto: "100"},
        // {id: 4, simple: true, dim: 3, codeAB: "6192436000137", heigPhoto: "190", widtPhoto: "100"},
    ]},
    {id: 2, page: 22, genre: "BSP", color: "#28166c", articles: [
        {id: 1, simple: true, dim: 3, codeAB: "6192507800444", heigPhoto: "190", widtPhoto: "100"},
        {id: 2, simple: true, dim: 3, codeAB: "6192507800031", heigPhoto: "190", widtPhoto: "100"},
        {id: 3, simple: true, dim: 3, codeAB: "6192507800215", heigPhoto: "190", widtPhoto: "100"},
        {id: 4, simple: true, dim: 3, codeAB: "6192507800017", heigPhoto: "190", widtPhoto: "100"},
        {id: 5, simple: true, dim: 3, codeAB: "6192507800024", heigPhoto: "190", widtPhoto: "100"},
        {id: 6, simple: true, dim: 3, codeAB: "6192507800376", heigPhoto: "190", widtPhoto: "100"},
        {id: 7, simple: true, dim: 3, codeAB: "6192507800222", heigPhoto: "190", widtPhoto: "100"},
        {id: 8, simple: true, dim: 3, codeAB: "6192507800536", heigPhoto: "190", widtPhoto: "100"},
        {id: 9, simple: true, dim: 3, codeAB: "6192507800321", heigPhoto: "190", widtPhoto: "100"},
        {id: 10, simple: true, dim: 3, codeAB: "6192507800277", heigPhoto: "190", widtPhoto: "100"},
        {id: 11, simple: true, dim: 3, codeAB: "6192507800208", heigPhoto: "190", widtPhoto: "100"},
        {id: 12, simple: true, dim: 3, codeAB: "619250780028", heigPhoto: "190", widtPhoto: "100"},
        {id: 13, simple: true, dim: 3, codeAB: "6192507800284", heigPhoto: "190", widtPhoto: "100"},
        // {id: 14, simple: true, dim: 3, codeAB: "6192507800444", heigPhoto: "190", widtPhoto: "100"},
        // {id: 15, simple: true, dim: 3, codeAB: "6192507800031", heigPhoto: "190", widtPhoto: "100"},
        // {id: 16, simple: true, dim: 3, codeAB: "6192507800215", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id: 2, page: 23, genre: "BSP", color: "#28166c", articles: [
        {id: 1, simple: true, dim: 3, codeAB: "6192507800390", heigPhoto: "190", widtPhoto: "100"},
        {id: 2, simple: true, dim: 3, codeAB: "6192507800291", heigPhoto: "190", widtPhoto: "100"},
        {id: 3, simple: true, dim: 3, codeAB: "6192507800239", heigPhoto: "190", widtPhoto: "100"},
        {id: 4, simple: true, dim: 3, codeAB: "6192507800178", heigPhoto: "190", widtPhoto: "100"},
        {id: 5, simple: true, dim: 3, codeAB: "6192507800116", heigPhoto: "190", widtPhoto: "100"},
        {id: 6, simple: true, dim: 3, codeAB: "6192507800048", heigPhoto: "190", widtPhoto: "100"},
        {id: 7, simple: true, dim: 3, codeAB: "6192507800161", heigPhoto: "190", widtPhoto: "100"},
        {id: 8, simple: true, dim: 3, codeAB: "6192507800369", heigPhoto: "190", widtPhoto: "100"},
        {id: 9, simple: true, dim: 3, codeAB: "6192507800383", heigPhoto: "190", widtPhoto: "100"},
        {id: 10, simple: true, dim: 3, codeAB: "6192507800338", heigPhoto: "190", widtPhoto: "100"},
        {id: 11, simple: true, dim: 3, codeAB: "6192507800093", heigPhoto: "190", widtPhoto: "100"},
        {id: 12, simple: true, dim: 3, codeAB: "6192507800123", heigPhoto: "190", widtPhoto: "100"},
        {id: 13, simple: true, dim: 3, codeAB: "151", heigPhoto: "190", widtPhoto: "100"},
        {id: 14, simple: true, dim: 3, codeAB: "6192507800147", heigPhoto: "190", widtPhoto: "100"},
        {id: 15, simple: true, dim: 3, codeAB: "6192507800079", heigPhoto: "190", widtPhoto: "100"},
        {id: 16, simple: true, dim: 3, codeAB: "6192507800154", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id: 2, page: 24, genre: "BSP", color: "#28166c", articles: [
        {id: 1, simple: true, dim: 3, codeAB: "103014260742195", heigPhoto: "190", widtPhoto: "100"},
        {id: 2, simple: true, dim: 3, codeAB: "6192507800314", heigPhoto: "190", widtPhoto: "100"},
        {id: 3, simple: true, dim: 3, codeAB: "6192507800307", heigPhoto: "190", widtPhoto: "100"},
        {id: 4, simple: true, dim: 3, codeAB: "6192507800055", heigPhoto: "190", widtPhoto: "100"},
        {id: 5, simple: true, dim: 3, codeAB: "6192507800109", heigPhoto: "190", widtPhoto: "100"},
        {id: 6, simple: true, dim: 3, codeAB: "6192507800185", heigPhoto: "190", widtPhoto: "100"},
        {id: 7, simple: true, dim: 3, codeAB: "6192507800246", heigPhoto: "190", widtPhoto: "100"},
        {id: 8, simple: true, dim: 3, codeAB: "6192507800253", heigPhoto: "190", widtPhoto: "100"},
        {id: 9, simple: true, dim: 3, codeAB: "6192507800260", heigPhoto: "190", widtPhoto: "100"},
        {id: 10, simple: true, dim: 3, codeAB: "6192507800406", heigPhoto: "190", widtPhoto: "100"},
        // {id: 1, simple: true, dim: 3, codeAB: "103014260742195", heigPhoto: "190", widtPhoto: "100"},
        // {id: 2, simple: true, dim: 3, codeAB: "6192507800314", heigPhoto: "190", widtPhoto: "100"},
        // {id: 3, simple: true, dim: 3, codeAB: "6192507800307", heigPhoto: "190", widtPhoto: "100"},
        // {id: 4, simple: true, dim: 3, codeAB: "6192507800055", heigPhoto: "190", widtPhoto: "100"},
        // {id: 5, simple: true, dim: 3, codeAB: "6192507800109", heigPhoto: "190", widtPhoto: "100"},
        // {id: 6, simple: true, dim: 3, codeAB: "6192507800185", heigPhoto: "190", widtPhoto: "100"},
    ]},
    {id: 2, page: 25, genre: "ODEO", color: "#c25a44", articles: [
        // {id: 1, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 2, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 3, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 4, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 5, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 6, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 7, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 8, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 9, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 10, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 11, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 12, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 13, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 14, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 15, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 16, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id: 2, page: 26, genre: "CHOC", color: "#c92024", articles: [
        {id: 1, simple: true, dim: 3, codeAB: "6192002020293", heigPhoto: "190", widtPhoto: "100"},
        {id: 2, simple: true, dim: 3, codeAB: "6192430002533", heigPhoto: "190", widtPhoto: "100"},
        {id: 3, simple: true, dim: 3, codeAB: "6192430002540", heigPhoto: "190", widtPhoto: "100"},
        {id: 4, simple: true, dim: 3, codeAB: "6192430002519", heigPhoto: "190", widtPhoto: "100"},
        {id: 5, simple: true, dim: 3, codeAB: "6192430002526", heigPhoto: "190", widtPhoto: "100"},
        {id: 6, simple: true, dim: 3, codeAB: "6192002020330", heigPhoto: "190", widtPhoto: "100"},
        {id: 7, simple: true, dim: 3, codeAB: "6192002011062", heigPhoto: "190", widtPhoto: "100"},
        {id: 8, simple: true, dim: 3, codeAB: "6192002010942", heigPhoto: "190", widtPhoto: "100"},
        {id: 9, simple: true, dim: 3, codeAB: "6192002080167", heigPhoto: "190", widtPhoto: "100"},
        {id: 10, simple: true, dim: 3, codeAB: "6192002020026", heigPhoto: "190", widtPhoto: "100"},
        {id: 11, simple: true, dim: 3, codeAB: "6192002020422", heigPhoto: "190", widtPhoto: "100"},
        {id: 12, simple: true, dim: 3, codeAB: "6192002010515", heigPhoto: "190", widtPhoto: "100"},
        {id: 13, simple: true, dim: 3, codeAB: "6192002020033", heigPhoto: "190", widtPhoto: "100"},
        {id: 14, simple: true, dim: 3, codeAB: "6192002030247", heigPhoto: "190", widtPhoto: "100"},
        {id: 15, simple: true, dim: 3, codeAB: "6192002030230", heigPhoto: "190", widtPhoto: "100"},
        {id: 16, simple: true, dim: 3, codeAB: "6192002030315", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id: 2, page: 27, genre: "PLASTIQUE", color: "#5dd620", articles: [
        {id: 1, simple: true, dim: 3, codeAB: "6193603534752", heigPhoto: "190", widtPhoto: "100"},
        {id: 2, simple: true, dim: 3, codeAB: "224", heigPhoto: "190", widtPhoto: "100"},
        {id: 3, simple: true, dim: 3, codeAB: "226", heigPhoto: "190", widtPhoto: "100"},
        {id: 4, simple: true, dim: 3, codeAB: "6193603550455", heigPhoto: "190", widtPhoto: "100"},
        {id: 5, simple: true, dim: 3, codeAB: "240", heigPhoto: "190", widtPhoto: "100"},
        {id: 6, simple: true, dim: 3, codeAB: "305", heigPhoto: "190", widtPhoto: "100"},
        {id: 7, simple: true, dim: 3, codeAB: "6192502705607", heigPhoto: "190", widtPhoto: "100"},
        {id: 8, simple: true, dim: 3, codeAB: "6193603574031", heigPhoto: "190", widtPhoto: "100"},
        {id: 9, simple: true, dim: 3, codeAB: "6192503671130", heigPhoto: "190", widtPhoto: "100"},
        {id: 10, simple: true, dim: 3, codeAB: "6192502701517", heigPhoto: "190", widtPhoto: "100"},
        {id: 11, simple: true, dim: 3, codeAB: "6193603539184", heigPhoto: "190", widtPhoto: "100"},
        {id: 12, simple: true, dim: 3, codeAB: "6192502700732", heigPhoto: "190", widtPhoto: "100"},
        {id: 13, simple: true, dim: 3, codeAB: "6192502703115", heigPhoto: "190", widtPhoto: "100"},
        {id: 14, simple: true, dim: 3, codeAB: "244", heigPhoto: "190", widtPhoto: "100"},
        {id: 15, simple: true, dim: 3, codeAB: "6192502704488", heigPhoto: "190", widtPhoto: "100"},
        {id: 16, simple: true, dim: 3, codeAB: "6193603570309", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id: 2, page: 28, genre: "KACHMIR", color: "#fa9716", articles: [
        {id: 1, simple: true, dim: 3, codeAB: "6192418400948", heigPhoto: "190", widtPhoto: "100"},
        {id: 2, simple: true, dim: 3, codeAB: "6192423500053", heigPhoto: "190", widtPhoto: "100"},
        // {id: 3, simple: true, dim: 3, codeAB: "6192461300042", heigPhoto: "190", widtPhoto: "100"},
        {id: 1, simple: true, dim: 3, codeAB: "6192461300042", heigPhoto: "190", widtPhoto: "100"},
        {id: 2, simple: true, dim: 3, codeAB: "123", heigPhoto: "190", widtPhoto: "100"},
        {id: 3, simple: true, dim: 3, codeAB: "122", heigPhoto: "190", widtPhoto: "100"},
        // {id: 1, simple: true, dim: 3, codeAB: "6192418400948", heigPhoto: "190", widtPhoto: "100"},
        // {id: 2, simple: true, dim: 3, codeAB: "6192423500053", heigPhoto: "190", widtPhoto: "100"},
        // {id: 3, simple: true, dim: 3, codeAB: "6192461300042", heigPhoto: "190", widtPhoto: "100"},
        // {id: 1, simple: true, dim: 3, codeAB: "6192418400948", heigPhoto: "190", widtPhoto: "100"},
        // {id: 2, simple: true, dim: 3, codeAB: "6192423500053", heigPhoto: "190", widtPhoto: "100"},
        // {id: 3, simple: true, dim: 3, codeAB: "6192461300042", heigPhoto: "190", widtPhoto: "100"},
        // {id: 1, simple: true, dim: 3, codeAB: "6192418400948", heigPhoto: "190", widtPhoto: "100"},
        // {id: 2, simple: true, dim: 3, codeAB: "6192423500053", heigPhoto: "190", widtPhoto: "100"},
        // {id: 3, simple: true, dim: 3, codeAB: "6192461300042", heigPhoto: "190", widtPhoto: "100"},
        // {id: 16, simple: true, dim: 3, codeAB: "6192461300042", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id: 2, page: 29, genre: "SAVONS", color: "#5dd620", articles: [
        {id: 1, simple: true, dim: 3, codeAB: "139", heigPhoto: "190", widtPhoto: "100"},
        {id: 2, simple: true, dim: 3, codeAB: "140", heigPhoto: "190", widtPhoto: "100"},
        {id: 3, simple: true, dim: 3, codeAB: "141", heigPhoto: "190", widtPhoto: "100"},
        {id: 4, simple: true, dim: 3, codeAB: "142", heigPhoto: "190", widtPhoto: "100"},
        {id: 5, simple: true, dim: 3, codeAB: "144", heigPhoto: "190", widtPhoto: "100"},
        {id: 6, simple: true, dim: 3, codeAB: "8690506484682", heigPhoto: "190", widtPhoto: "100"},
        {id: 7, simple: true, dim: 3, codeAB: "196", heigPhoto: "190", widtPhoto: "100"},
        {id: 8, simple: true, dim: 3, codeAB: "6281001816416", heigPhoto: "190", widtPhoto: "100"},
        {id: 9, simple: true, dim: 3, codeAB: "6192017315766", heigPhoto: "190", widtPhoto: "100"},
        // {id: 10, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 11, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 12, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 13, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 14, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 15, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 16, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id: 2, page: 30, genre: "SATEM", color: "#60388a", articles: [
        {id: 1, simple: true, dim: 3, codeAB: "6192013103961", heigPhoto: "190", widtPhoto: "100"},
        {id: 2, simple: true, dim: 3, codeAB: "6192013101431", heigPhoto: "190", widtPhoto: "100"},
        {id: 3, simple: true, dim: 3, codeAB: "6192013103732", heigPhoto: "190", widtPhoto: "100"},
        {id: 4, simple: true, dim: 3, codeAB: "6192013103671", heigPhoto: "190", widtPhoto: "100"},
        {id: 5, simple: true, dim: 3, codeAB: "6192013103404", heigPhoto: "190", widtPhoto: "100"},
        {id: 6, simple: true, dim: 3, codeAB: "6192013103886", heigPhoto: "190", widtPhoto: "100"},
        {id: 7, simple: true, dim: 3, codeAB: "6192017335672", heigPhoto: "190", widtPhoto: "100"},
        {id: 8, simple: true, dim: 3, codeAB: "6192017321675", heigPhoto: "190", widtPhoto: "100"},
        {id: 9, simple: true, dim: 3, codeAB: "5055810009410", heigPhoto: "190", widtPhoto: "100"},
        // {id: 1, simple: true, dim: 3, codeAB: "6192013103961", heigPhoto: "190", widtPhoto: "100"},
        // {id: 2, simple: true, dim: 3, codeAB: "6192013101431", heigPhoto: "190", widtPhoto: "100"},
        // {id: 3, simple: true, dim: 3, codeAB: "6192013103732", heigPhoto: "190", widtPhoto: "100"},
        // {id: 4, simple: true, dim: 3, codeAB: "6192013103671", heigPhoto: "190", widtPhoto: "100"},
        // {id: 5, simple: true, dim: 3, codeAB: "6192013103404", heigPhoto: "190", widtPhoto: "100"},
        // {id: 6, simple: true, dim: 3, codeAB: "6192013103886", heigPhoto: "190", widtPhoto: "100"},
        // {id: 7, simple: true, dim: 3, codeAB: "6192017335672", heigPhoto: "190", widtPhoto: "100"},
    ]},
    {id: 2, page: 31, genre: "PAPIER", color: "#5dd620", articles: [
        {id: 1, simple: true, dim: 3, codeAB: "6192903500016", heigPhoto: "190", widtPhoto: "100"},
        {id: 2, simple: true, dim: 3, codeAB: "6192100100675", heigPhoto: "190", widtPhoto: "100"},
        {id: 3, simple: true, dim: 3, codeAB: "6192106800579", heigPhoto: "190", widtPhoto: "100"},
        {id: 4, simple: true, dim: 3, codeAB: "162", heigPhoto: "190", widtPhoto: "100"},
        {id: 5, simple: true, dim: 3, codeAB: "5602024344891", heigPhoto: "190", widtPhoto: "100"},
        // {id: 6, simple: true, dim: 3, codeAB: "8690506484682", heigPhoto: "190", widtPhoto: "100"},
        // {id: 7, simple: true, dim: 3, codeAB: "196", heigPhoto: "190", widtPhoto: "100"},
        // {id: 8, simple: true, dim: 3, codeAB: "6281001816416", heigPhoto: "190", widtPhoto: "100"},
        // {id: 9, simple: true, dim: 3, codeAB: "6192017315766", heigPhoto: "190", widtPhoto: "100"},
        // {id: 10, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 11, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 12, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 13, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 14, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 15, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 16, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id: 2, page: 32, genre: "DIVERS", color: "#5dd620", articles: [
        {id: 1, simple: true, dim: 3, codeAB: "167", heigPhoto: "190", widtPhoto: "100"},
        {id: 2, simple: true, dim: 3, codeAB: "6192102001406", heigPhoto: "190", widtPhoto: "100"},
        {id: 3, simple: true, dim: 3, codeAB: "3086124904315", heigPhoto: "190", widtPhoto: "100"},
        {id: 4, simple: true, dim: 3, codeAB: "125", heigPhoto: "190", widtPhoto: "100"},
        {id: 5, simple: true, dim: 3, codeAB: "115", heigPhoto: "190", widtPhoto: "100"},
        // {id: 6, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 7, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 8, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 9, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 10, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 11, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 12, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 13, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 14, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 15, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 16, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id: 2, page: 33, genre: "DIVERS", color: "#5dd620", articles: [
        {id: 1, simple: true, dim: 3, codeAB: "109", heigPhoto: "190", widtPhoto: "100"},
        {id: 2, simple: true, dim: 3, codeAB: "108", heigPhoto: "190", widtPhoto: "100"},
        {id: 3, simple: true, dim: 3, codeAB: "107", heigPhoto: "190", widtPhoto: "100"},
        {id: 4, simple: true, dim: 3, codeAB: "106", heigPhoto: "190", widtPhoto: "100"},
        {id: 5, simple: true, dim: 3, codeAB: "6131778001525", heigPhoto: "190", widtPhoto: "100"},
        // {id: 6, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 7, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 8, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 9, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 10, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 11, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 12, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 13, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 14, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 15, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 16, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id: 2, page: 34, genre: "DIVERS", color: "#5dd620", articles: [
        {id: 1, simple: true, dim: 3, codeAB: "6981959998850", heigPhoto: "190", widtPhoto: "100"},
        {id: 2, simple: true, dim: 3, codeAB: "8697405603268", heigPhoto: "190", widtPhoto: "100"},
        {id: 3, simple: true, dim: 3, codeAB: "8697422820884", heigPhoto: "190", widtPhoto: "100"},
        {id: 4, simple: true, dim: 3, codeAB: "8964000798300", heigPhoto: "190", widtPhoto: "100"},
        {id: 5, simple: true, dim: 3, codeAB: "6192434500110", heigPhoto: "190", widtPhoto: "100"},
        // {id: 6, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 7, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 8, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 9, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 10, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 11, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 12, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 13, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 14, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 15, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 16, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id: 2, page: 35, genre: "DIVERS", color: "#5dd620", articles: [
        {id: 1, simple: true, dim: 3, codeAB: "6936805400094", heigPhoto: "190", widtPhoto: "100"},
        {id: 2, simple: true, dim: 3, codeAB: "6900007714211", heigPhoto: "190", widtPhoto: "100"},
        {id: 3, simple: true, dim: 3, codeAB: "8682742370416", heigPhoto: "190", widtPhoto: "100"},
        {id: 4, simple: true, dim: 3, codeAB: "6193000904028", heigPhoto: "190", widtPhoto: "100"},
        {id: 5, simple: true, dim: 3, codeAB: "3014260419745", heigPhoto: "190", widtPhoto: "100"},
        // {id: 6, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 7, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 8, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 9, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 10, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 11, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 12, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 13, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 14, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 15, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 16, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id: 2, page: 36, genre: "DIVERS", color: "#5dd620", articles: [
        {id: 1, simple: true, dim: 3, codeAB: "6970163015163", heigPhoto: "190", widtPhoto: "100"},
        {id: 2, simple: true, dim: 3, codeAB: "6209518393404", heigPhoto: "190", widtPhoto: "100"},
        {id: 3, simple: true, dim: 3, codeAB: "6932290332384", heigPhoto: "190", widtPhoto: "100"},
        {id: 4, simple: true, dim: 3, codeAB: "403", heigPhoto: "190", widtPhoto: "100"},
        {id: 5, simple: true, dim: 3, codeAB: "6936292150717", heigPhoto: "190", widtPhoto: "100"},
        // {id: 6, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 7, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 8, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 9, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 10, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 11, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 12, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 13, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 14, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 15, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 16, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id: 2, page: 37, genre: "DIVERS", color: "#5dd620", articles: [
        {id: 1, simple: true, dim: 3, codeAB: "312", heigPhoto: "190", widtPhoto: "100"},
        {id: 2, simple: true, dim: 3, codeAB: "309", heigPhoto: "190", widtPhoto: "100"},
        {id: 3, simple: true, dim: 3, codeAB: "229", heigPhoto: "190", widtPhoto: "100"},
        {id: 4, simple: true, dim: 3, codeAB: "8964000765128", heigPhoto: "190", widtPhoto: "100"},
        {id: 5, simple: true, dim: 3, codeAB: "6291100735853", heigPhoto: "190", widtPhoto: "100"},
        // {id: 6, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 7, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 8, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 9, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 10, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 11, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 12, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 13, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 14, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 15, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 16, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id: 2, page: 38, genre: "DIVERS", color: "#5dd620", articles: [
        {id: 1, simple: true, dim: 3, codeAB: "6930487160161", heigPhoto: "190", widtPhoto: "100"},
        {id: 2, simple: true, dim: 3, codeAB: "8697422822109", heigPhoto: "190", widtPhoto: "100"},
        {id: 3, simple: true, dim: 3, codeAB: "6920709309720", heigPhoto: "190", widtPhoto: "100"},
        {id: 4, simple: true, dim: 3, codeAB: "8697422821546", heigPhoto: "190", widtPhoto: "100"},
        {id: 5, simple: true, dim: 3, codeAB: "8697422820297", heigPhoto: "190", widtPhoto: "100"},
        // {id: 6, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 7, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 8, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 9, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 10, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 11, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 12, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 13, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 14, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 15, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 16, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id: 2, page: 39, genre: "DIVERS", color: "#5dd620", articles: [
        {id: 1, simple: true, dim: 3, codeAB: "8003510002740", heigPhoto: "190", widtPhoto: "100"},
        {id: 2, simple: true, dim: 3, codeAB: "6920212901398", heigPhoto: "190", widtPhoto: "100"},
        {id: 3, simple: true, dim: 3, codeAB: "6931717812485", heigPhoto: "190", widtPhoto: "100"},
        {id: 4, simple: true, dim: 3, codeAB: "6193001346100", heigPhoto: "190", widtPhoto: "100"},
        // {id: 5, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 6, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 7, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 8, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 9, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 10, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 11, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 12, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 13, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 14, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 15, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 16, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"}
    ]},
    {id: 2, page: 40, genre: "DIVERS", color: "#5dd620", articles: [
        // {id: 1, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 2, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 3, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 4, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 5, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 6, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 7, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 8, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 9, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 10, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 11, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 12, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 13, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 14, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 15, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"},
        // {id: 16, simple: true, dim: 3, codeAB: "6192000716341", heigPhoto: "190", widtPhoto: "100"}
    ]},

]
export default CosmoDefaultCateg