import React from 'react';
import BackCard from '../Assets/backCard';
import InputLinks from '../Assets/linksData';
function RechercheVente() {
    return ( <>
        <BackCard data={InputLinks.backCard.vtRech}/>
        <br />
        RechercheVnete
    </> );
}

export default RechercheVente;